import { bufferTime, catchError, concatMap, filter, forkJoin, map, Observable, of, scan } from 'rxjs';
import { QuantorApiService } from '../../../quantor-api/quantor-api.service';
import { AddressValueUsd } from '../../../quantor-api';



export function getValuesByAddresses$(
  addresses$: Observable<string[]>,
  quantorApi: QuantorApiService,
): Observable<Record<string, number>> {
  return addresses$.pipe(
    bufferTime(100),
    map((arr) => arr.reduce((acc, addresses) => ([...new Set([...acc, ...addresses])]), [])),
    scan<string[], { allAddresses: string[], newAddresses: string[] }>((oldState, addresses) => {
      const oldAllAddressesSet = new Set(oldState.allAddresses);
      const allAddresses = [...new Set([...oldState.allAddresses, ...addresses])];
      const newAddresses = allAddresses.filter(addr => !oldAllAddressesSet.has(addr));
      return { allAddresses, newAddresses };
    }, { allAddresses: [], newAddresses: [] }),
    filter(({ newAddresses }) => newAddresses.length > 0),
    concatMap(({ newAddresses }) => {
      return getTotalCurrentValueInChunks(quantorApi, newAddresses, 10).pipe(
        map((addresses) => {
          const resAddresses = addresses.reduce((acc, item) => {
            acc[item.address.toLowerCase()] = item.value_usd;
            return acc;
          }, {} as Record<string, number>);

          return newAddresses.reduce((acc, address0) => {
            const address = address0.toLowerCase();
            acc[address] = resAddresses?.[address] ?? 0;
            return acc;
          }, {} as Record<string, number>);
        })
      );
    }),
    scan<Record<string, number>, Record<string, number>>((acc, newMap) => {
      return { ...acc, ...newMap };
    }, {})
  )
}

function getTotalCurrentValueInChunks(quantorApi: QuantorApiService, addresses: string[], chunkSize: number): Observable<AddressValueUsd[]> {
  const chunks = Array.from({ length: Math.ceil(addresses.length / chunkSize) }).map((_, i) => {
    return addresses.slice(i * chunkSize, i * chunkSize + chunkSize);
  });

  const addressesChunks$ = chunks.map((chunk, i) => quantorApi.getTotalCurrentValue(chunk).pipe(
    map((res) => res.addresses),
    catchError(() => of([]))
  ));

  return forkJoin(addressesChunks$).pipe(
    map((addressesChunks) => addressesChunks.reduce((acc, addresses) => ([...acc, ...addresses]), []))
  );
}
