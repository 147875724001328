import { APP_INITIALIZER, Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ThemeService } from './theme.service';

export const THEME_INITIALIZER: Provider = {
  provide: APP_INITIALIZER,
  deps: [ThemeService],
  multi: true,
  useFactory: (theme: ThemeService) => (): Observable<boolean> => {
    /* Theme is applied instantly before app load */
    return of(true);
  }
}
