import { Injectable } from '@angular/core';
import { SelectedAddresses } from './selected-addresses';
import { map, merge, Observable, tap } from 'rxjs';
import { WalletConnectService } from '../wallet-connect/wallet-connect.service';
import { MAX_SELECTION_ADDRESSES_COUNT } from './entities';

@Injectable({
  providedIn: 'root'
})
export class AccountAddressesService {
  private selection = new SelectedAddresses('account-selected-addresses', this.maxSelectionAddressesCount);
  private watchlist = new SelectedAddresses('account-watchlist-addresses');

  selectedAddresses$ = this.selection.valueChanges;

  watchlistAddresses$ = this.watchlist.valueChanges;

  connectedAddresses$ = this.walletConnect.connectedAddresses$.pipe(
    map((addresses) => addresses.map(a => a.toLowerCase()))
  );

  constructor(
    readonly walletConnect: WalletConnectService,
  ) {
  }

  toggleWatchlistAddress(address: string, isAdd: boolean) {
    this.watchlist.toggleSelected(address, isAdd);
    this.fixSelection();
  }

  toggleSelectedSet(addresses: string[], isSelected: boolean): boolean {
    const success = this.selection.toggleSelectedSet(addresses, isSelected);
    this.fixSelection();
    return success;
  }

  toggleSelected(address: string, isSelected: boolean | undefined = undefined): boolean {
    if (
      this.selection.selectedAddresses.length === 1 &&
      this.selection.selectedAddresses[0] === address.toLowerCase() &&
      !isSelected
    ) {
      return false;
    }

    const success = this.selection.toggleSelected(address, isSelected);
    this.fixSelection();
    return success;
  }

  private fixSelection() {
    const connected = this.walletConnect.connectedAddresses.map(a => a.toLowerCase());
    const watchlist = this.watchlist.selectedAddresses;

    const excessiveSelected = this.selection.selectedAddresses.filter((s) => (
      !connected.includes(s.toLowerCase()) &&
      !watchlist.includes(s.toLowerCase())
    ));

    if (excessiveSelected.length) {
      this.selection.toggleSelectedSet(excessiveSelected, false);
    }

    const selected = this.selection.selectedAddresses;
    if (!selected.length && connected.length) {
      this.selection.toggleSelected(connected[0], true);
    } else if (!selected.length && watchlist.length) {
      this.selection.toggleSelected(watchlist[0], true);
    }
  }

  trackSelectionChanges(): Observable<void> {
    const connected$ = this.connectedAddresses$.pipe(
      tap(() => this.fixSelection()),
      map(() => undefined),
    );

    return merge(
      connected$,
      this.watchlist.saveSelectionChanges(),
      this.selection.saveSelectionChanges(),
    )
  }

  get selectedAddresses() {
    return this.selection.selectedAddresses;
  }

  get maxSelectionAddressesCount() {
    return MAX_SELECTION_ADDRESSES_COUNT;
  }
}
