import { NgModule } from '@angular/core';
import { BROWSER_VERSION_INITIALIZER, BrowserVersionService } from './browser-version.service';



@NgModule({
  providers: [
    BrowserVersionService,
    BROWSER_VERSION_INITIALIZER,
  ]
})
export class BrowserVersionModule {
}


