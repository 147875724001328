import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, of, shareReplay } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnsLocalCache } from './ens-local-cache';


interface ReverseLookupResponse {
  result: {
    protocol: 'ENS' | string;
    domain: string;
  }[] | null;
}

export const ENS_API_URL = new InjectionToken<string>('__ENS_API_URL__');

@Injectable({
  providedIn: 'root',
})
export class EnsService {
  private ensNameByAddressCache: Record<string, Observable<string | null>> = {};
  private ensNameByAddressLocalStorage = new EnsLocalCache('ens-name-by-address-cache', 86400 * 1000);

  constructor(
    @Inject(ENS_API_URL) readonly apiUrl: string,
    readonly http: HttpClient,
  ) {
  }

  lookupENSAddress(address: string): Observable<string | null> {
    if (this.ensNameByAddressLocalStorage.hasItem(address)) {
      return of(this.ensNameByAddressLocalStorage.getItem(address));
    }

    if (!this.ensNameByAddressCache[address]) {
      this.ensNameByAddressCache[address] = this.http.get<ReverseLookupResponse>(`${this.apiUrl}/reverse-lookup?address=${address}`).pipe(
        map((response) => {
          const responseDomain = response?.result?.find(item => item?.protocol === 'ENS')?.domain ?? null;
          this.ensNameByAddressLocalStorage.setItem(address, responseDomain);
          return responseDomain;
        }),
        catchError(() => {
          return of(null);
        }),
        shareReplay(1),
      );
    }

    return this.ensNameByAddressCache[address];
  }
}
