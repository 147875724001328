import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, } from 'rxjs';

@Injectable()
export class AuthService {
  getAccessToken(): string | null {
    return 'f5QhzNvgGZT1IzkGMX09ejtodHssh14vBoYgF1QW';
  }

  logout(): Observable<void> {
    return EMPTY;
  }
}
