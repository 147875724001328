import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElasticContainerStaticContentDirective } from './elastic-container-static-content.directive';
import { TuiElasticContainerModule } from '@taiga-ui/kit';
import { ElasticContainerComponent } from './elastic-container.component';



@NgModule({
  declarations: [
    ElasticContainerComponent,
    ElasticContainerStaticContentDirective
  ],
  exports: [
    ElasticContainerComponent,
    ElasticContainerStaticContentDirective
  ],
  imports: [
    CommonModule,
    TuiElasticContainerModule
  ]
})
export class ElasticContainerModule { }
