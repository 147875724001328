import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ThemeService } from '../../../../modules/theme/theme.service';
import { ThemeName } from '../../../../modules/theme';


@Component({
  selector: 'app-preferences-button',
  templateUrl: './preferences-button.component.html',
  styleUrls: ['./preferences-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreferencesButtonComponent implements OnDestroy {
  isPreferencesDropdownOpen$ = new BehaviorSubject(false);
  isDarkThemeControl = new FormControl<ThemeName | null>(this.theme.themeName);
  subscriptions: Subscription[] = [];

  constructor(
    readonly theme: ThemeService,
  ) {
    this.subscriptions.push(...[
      this.theme.themeName$.subscribe(name => {
        if (this.isDarkThemeControl.value !== name) {
          this.isDarkThemeControl.setValue(name);
        }
      }),
      this.isDarkThemeControl.valueChanges.subscribe(name => {
        if (name && this.theme.themeName !== name) {
          this.theme.setTheme(name);
        }
      }),
    ]);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
