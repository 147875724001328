<app-tabs class="tabs" [active]="activeTab$ | async" (activeChange)="onActiveChange($event)">
  <button *ngFor="let tab of tabs"
          appTab
          tuiHintDirection="top"
          [tuiHintShowDelay]="100"
          [tuiHintHideDelay]="0"
          [tuiHint]="tab.disabled ? disabledTooltip : null"
          [value]="tab.link"
          [disabled]="tab.disabled ?? false">
    {{ tab.title }}
  </button>
</app-tabs>

<ng-template #disabledTooltip>
  This feature unavailable
</ng-template>
