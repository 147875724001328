<ng-container *ngIf="state$ | async as state">
  <div class="blue" [class.active]="state.type === 'blue'">
    <div class="gradient"></div>
    <div class="gradient"></div>
  </div>
  <div class="purple" [class.active]="state.type === 'purple'">
    <div class="gradient"></div>
    <div class="gradient"></div>
  </div>
</ng-container>
