import { catchError, defer, Observable, of, switchMap } from 'rxjs';
import { StorageUtil } from '@web3modal/core';

function downloadImage(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result as any);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function (error) {
      reject(error);
    }
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  })
}

interface StoredImageObject {
  url: string;
  data: string;
}

function getCachedImageByUrl(storageKey: string, url: string): Promise<string | null> {
  try {
    const storedValue = window.localStorage.getItem(storageKey);
    const stored = storedValue ? JSON.parse(storedValue) as StoredImageObject : null;

    if (stored?.url === url) {
      return Promise.resolve(stored.data);
    }

    return downloadImage(url).then((data) => {
      window.localStorage.setItem(storageKey, JSON.stringify({ data, url }));
      return data;
    }).catch(() => {
      return null;
    })
  } catch (e) {
    return Promise.resolve(null);
  }
}

function getConnectedWalletImageData(): Promise<string | null> {
  return new Promise((resolve) => {
    let url = null;
    try {
      url = StorageUtil.getConnectedWalletImageUrl() ?? null;
    } catch (e) {
      console.error(e)
    }

    if (!url) {
      resolve(null);
      return;
    }

    getCachedImageByUrl(
      'connected_wallet_image_data',
      url
    )
      .then((data) => resolve(data))
      .catch(() => resolve(null));
  })
}

export function loadConnectedWalletIcon(addresses$: Observable<string[]>): Observable<string | null> {
  return addresses$.pipe(
    switchMap((addresses) => {
      if (addresses.length) {
        return defer(() => getConnectedWalletImageData()).pipe(
          catchError(() => of(null))
        );
      } else {
        return of(null);
      }
    })
  )
}
