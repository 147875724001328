import { isValidAddress } from './is-valid-address';

export function addressesToUriComponent(addresses: string[]): string {
  const segments = Array.from(new Set([...addresses].map(a => a.toLowerCase())))
    .filter(a => isValidAddress(a))
    .sort();

  return segments.join('-');
}



export function uriComponentToAddresses(uri: string): string[] {
  const segments = Array.from(new Set(uri.split('-').map(a => a.toLowerCase())))
    .filter(a => isValidAddress(a))
    .sort();

  return segments;
}
