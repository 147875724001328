import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EnsService } from '../../../ens/ens.service';
import { map, Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { TuiLetModule } from '@taiga-ui/cdk';



@Component({
  selector: 'app-account-image',
  templateUrl: './account-image.component.html',
  styleUrls: ['./account-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    TuiLetModule
  ]
})
export class AccountImageComponent implements OnChanges {
  @Input() address?: string;
  avatarENS$?: Observable<string | null>;
  avatar?: string;
  errorENS = false;
  error = false;

  constructor(
    readonly ens: EnsService
  ) {
  }

  private avatarAddr(address: string): string {
    return `https://effigy.im/a/${address}.svg`
  }

  private avatarENS(name: string): string {
    return `https://metadata.ens.domains/mainnet/avatar/${name}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('address' in changes && this.address) {
      this.error = false;
      this.errorENS = false;
      this.avatar = this.avatarAddr(this.address);
      this.avatarENS$ = this.ens.lookupENSAddress(this.address).pipe(
        map((name) => name ? this.avatarENS(name) : null)
      );
    }
  }
}
