import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeService } from './theme.service';
import { ForThemeDirective } from './for-theme.directive';
import { THEME_INITIALIZER } from './theme.initializer';



@NgModule({
  declarations: [
    ForThemeDirective,
  ],
  providers: [
    THEME_INITIALIZER,
    ThemeService,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ForThemeDirective,
  ]
})
export class ThemeModule { }
