import { NgModule } from '@angular/core';
import { ToastService } from './toast.service';
import { ToastMessageComponent } from './toast-message/toast-message.component';
import { ToastPortalComponent } from './toast-portal/toast-portal.component';
import { AsyncPipe, NgIf } from '@angular/common';



@NgModule({
  declarations: [
    ToastMessageComponent,
    ToastPortalComponent,
  ],
  providers: [
    ToastService
  ],
  imports: [
    AsyncPipe,
    NgIf
  ],
  exports: [
    ToastPortalComponent,
  ]
})
export class ToastModule { }
