import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { applyThemePaletteVariables, Theme, THEME_NAMES, ThemeName } from './entities';
import { THEMES } from './themes';



@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnDestroy {
  private themeNameSubject = new BehaviorSubject<ThemeName>(loadCurrentThemeName() ?? detectBrowserTheme() ?? 'light');
  themeName$ = this.themeNameSubject.asObservable();
  theme$ = this.themeName$.pipe(
    map((name) => THEMES.find(item => item.name === name) as Theme)
  );
  subscriptions: Subscription[] = [];

  constructor() {
    this.subscriptions.push(...[
      this.themeName$.subscribe(saveCurrentThemeName),
      this.themeName$.subscribe(updateHtmlClasses),
      this.themeName$.subscribe(updateHtmlVariables),
    ]);
  }

  setTheme(themeName: ThemeName) {
    this.themeNameSubject.next(themeName);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }

  get themeName(): ThemeName {
    return this.themeNameSubject.value;
  }
}

const currentThemeNameStorageKey = 'current-theme-name';

function saveCurrentThemeName(themeName: ThemeName | null): void {
  if (!themeName) {
    localStorage.removeItem(currentThemeNameStorageKey);
  } else if (THEME_NAMES.includes(themeName)) {
    localStorage.setItem(currentThemeNameStorageKey, themeName);
  }
}

function updateHtmlClasses(themeName: ThemeName | null): void {
  const prefix = 'theme-';

  const classesToRemove: string[] = [];
  document.documentElement.classList.forEach((className) => {
    if (className.startsWith(prefix)) {
      classesToRemove.push(className);
    }
  });
  document.documentElement.classList.remove(...classesToRemove);

  if (themeName) {
    document.documentElement.classList.add(prefix + themeName);
  }
}

function updateHtmlVariables(themeName: ThemeName): void {
  const theme = THEMES.find(item => item.name === themeName);

  if (!theme) {
    throw new Error(`Theme "${themeName}" not found`);
  }

  applyThemePaletteVariables(theme.palette, document.documentElement);
}

function loadCurrentThemeName(): ThemeName | null {
  const loadedThemeName = localStorage.getItem(currentThemeNameStorageKey) as ThemeName | null;
  return (loadedThemeName && THEME_NAMES.includes(loadedThemeName)) ? loadedThemeName : null;
}

function detectBrowserTheme(): ThemeName | null {
  try {
    const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    return isDark ? 'dark' : 'light';
  } catch (e) {
    return null;
  }
}
