import { Theme } from './entities';

export const THEMES: Theme[] = [
  {
    name: 'light',
    palette: {
      staticBrand: '#2F8AF5',
      staticWhite: '#FFFFFF',
      staticWhite60: '#FFFFFF99',
      staticWhite25: '#FFFFFF40',
      staticWhite10: '#FFFFFF1A',
      staticBlack: '#222222',
      staticGreen: '#21C187',

      textIconsMain: '#222222',
      textIconsGray500: '#6C86AD',
      textIconsGray400: '#9DB0CD',
      textIconsGray300: '#BAC7DA',
      textIconsGray200: '#E3E7EE',
      textIconsApprove: '#008A7C',
      textIconsWarning: '#FF9C08',
      textIconsError: '#E3402A',

      bgMain: '#FFFFFF',
      bg2ry: '#F7F9FF',
      bg3ry: '#E8EDFA',
      bgInfo2ry: '#2F8AF529',
      bgSuccess2ry: '#008A7C29',
      bgWarning2ry: '#FF9C0833',
      bgError2ry: '#DA170121',
      bgWarning: '#FF9C08',
      bgError: '#E3402A',
      bgInputMain: '#F3F5FE',
      bgInput2ry: '#E8EDFA',
      bgTabSelected: '#FFFFFF',
      bgBody: '#F7F9FF',
      bgInput3ry: '#E8EDFA',

      borderMain: '#CADAF4',
      border2ry: '#E3E7EE',
      borderFocused: '#2F8AF529',

      dropdownShadow: '#6C86AD33',

      statesButtonDisabled: '#F3F5FE',
      statesControlDisabled: '#E3E7EE',
      statesRipple: '#6C86AD1F',
      statesBrandHover: '#5EA5F8',
      statesInfo2ryHover: '#2F8AF51F',
      statesApproveHover: '#00BDAA',
      statesSuccess2ryHover: '#008A7C3D',
      statesWarningHover: '#FFB13D',
      statesWarning2ryHover: '#FF9C081F',
      statesErrorHover: '#E96958',
      statesError2ryHover: '#C13D541F',
    }
  },
  {
    name: 'dark',
    palette: {
      staticBrand: '#2F8AF5',
      staticWhite: '#FFFFFF',
      staticWhite60: '#FFFFFF99',
      staticWhite25: '#FFFFFF40',
      staticWhite10: '#FFFFFF1A',
      staticBlack: '#222222',
      staticGreen: '#21C187',

      textIconsMain: '#FBFBFB',
      textIconsGray500: '#6C86AD',
      textIconsGray400: '#44556F',
      textIconsGray300: '#37445A',
      textIconsGray200: '#202835',
      textIconsApprove: '#21C187',
      textIconsWarning: '#FF9C08',
      textIconsError: '#F04832',

      bgMain: '#141924',
      bg2ry: '#10141C',
      bg3ry: '#263042',
      bgInfo2ry: '#2F8AF529',
      bgSuccess2ry: '#33776B40',
      bgWarning2ry: '#FF9C0840',
      bgError2ry: '#F0483229',
      bgWarning: '#FF9C08',
      bgError: '#F04832',
      bgInputMain: '#06070A',
      bgInput2ry: '#06070A',
      bgTabSelected: '#2B374A',
      bgBody: '#06070A',
      bgInput3ry: '#10141C',

      borderMain: '#2F3B4D',
      border2ry: '#232C3D',
      borderFocused: '#2F8AF529',

      dropdownShadow: '#06070A85',

      statesButtonDisabled: '#263042',
      statesControlDisabled: '#232C3D',
      statesRipple: '#6C86AD29',
      statesBrandHover: '#0B6FE5',
      statesInfo2ryHover: '#2F8AF53D',
      statesApproveHover: '#199468',
      statesSuccess2ryHover: '#33776B59',
      statesWarningHover: '#D68100',
      statesWarning2ryHover: '#FF9C0859',
      statesErrorHover: '#DF2810',
      statesError2ryHover: '#F048323D',
    },
  }
];
