import { Injectable } from '@angular/core';
import { ToastPortal } from './entities';



@Injectable({
  providedIn: 'root'
})
export class ToastService {
  duration = 3200;
  portal?: ToastPortal;
  pinnedToastId: number | null = null;

  registerPortal(portal: ToastPortal) {
    this.portal = portal;
  }

  unpin() {
    if (this.pinnedToastId !== null) {
      this.portal?.discard(this.pinnedToastId);
      this.pinnedToastId = null;
    }
  }

  pin(text: string) {
    this.unpin();

    this.pinnedToastId = this.portal?.show({
      data: {
        message: text,
        icon: 'attention-triangle',
        type: 'warning',
      },
      duration: 'indefinite',
      pinned: true,
    }) ?? null;
  }

  show(text: string) {
    this.portal?.show({
      data: {
        message: text,
        icon: 'attention',
        type: 'info',
      },
      duration: this.duration
    });
  }

  error(text: string) {
    this.portal?.show({
      data: {
        message: text,
        icon: 'attention-triangle',
        type: 'error',
      },
      duration: this.duration
    });
  }
}
