import { Pipe, PipeTransform } from '@angular/core';
import { ethers } from 'ethers';

export type PrettyHashPipeParams = {
  head?: number,
  tail?: number,
};

@Pipe({
  name: 'prettyHash',
  pure: true,
  standalone: true,
})
export class PrettyHashPipe implements PipeTransform {
  transform(addressOrHash: string, params: PrettyHashPipeParams = {}): string {
    const {
      head = 6,
      tail = 4,
    } = params;

    let checksumAddress = addressOrHash.toLowerCase();
    try {
      // TODO: ethers.utils.getAddress - expects the wallet address to be logged in, not the transaction address
      checksumAddress = ethers.utils.getAddress(addressOrHash.toLowerCase());
    } catch (ignored) {
      //
    }

    return `${checksumAddress.slice(0, head)}...${checksumAddress.slice(checksumAddress.length - tail)}`;
  }
}
