import { InjectionToken, Type } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export type FloatingPopupState = 'appear' | 'disappear' | 'idle';

export const FLOATING_POPUP_STATE = new InjectionToken<BehaviorSubject<FloatingPopupState>>('__FLOATING_POPUP_STATE__');
export const FLOATING_POPUP_OPTIONS = new InjectionToken<FloatingPopupOptions>('__FLOATING_POPUP_OPTIONS__');
export const FLOATING_POPUP_CLOSER = new InjectionToken<Subject<void>>('__FLOATING_POPUP_CLOSER__');

export interface FloatingPopupOptions {
  component: Type<any>;
}

export interface FloatingPopupPortal {
  show(options: FloatingPopupOptions): void;
}
