import { NgModule } from '@angular/core';
import { SwitchToggleComponent } from './switch-toggle.component';
import { CommonModule } from '@angular/common';
import { SwitchToggleOptionComponent } from './switch-toggle-option/switch-toggle-option.component';

@NgModule({
  declarations: [
    SwitchToggleComponent,
    SwitchToggleOptionComponent,
  ],
  exports: [
    SwitchToggleComponent,
    SwitchToggleOptionComponent,
  ],
  imports: [CommonModule]
})
export class SwitchToggleModule {
}
