<tui-root [class.highlights-page]="isHighlightsBlock">
  <div class="page-wrap">
    <div class="app-header-wrap" [class.hidden]="isHighlightsBlock">
      <app-header></app-header>
    </div>

    <div class="page-content-wrap page-inner slide-animation">
      <div>
        <div class="container thin">
          <h1 class="title"> Explore </h1>
          <app-navigation-tabs></app-navigation-tabs>
        </div>
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</tui-root>
<app-background/>
<app-toast-portal *ngIf="!isHighlightsBlock"/>
<app-floating-popup-portal *ngIf="!isHighlightsBlock" />
