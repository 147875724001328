interface Entry {
  addedAt: number;
  value: string | null;
}

export class EnsLocalCache {
  readonly entries: Record<string, Entry> = {};

  constructor(
    readonly storageKey: string,
    readonly ttlMs: number
  ) {
    this.entries = loadEntriesMap(this.storageKey, this.ttlMs, Date.now());
  }

  setItem(key: string, value: string | null): void {
    if (this.entries[key]?.value === value) {
      return;
    }

    this.entries[key] = { addedAt: Date.now(), value };
    saveEntriesMap(this.storageKey, this.entries);
  }

  hasItem(key: string): boolean {
    return !!this.entries[key];
  }

  getItem(key: string): string | null {
    return this.entries[key]?.value ?? null;
  }
}

interface StoredEntry {
  k: string;
  v: string | null;
  a: number;
}

function saveEntriesMap(storageKey: string, entries: Record<string, Entry>): void {
  const entriesArray = Object.entries(entries).map(([key, entry]): StoredEntry => ({
    k: key,
    v: entry.value,
    a: entry.addedAt,
  }));

  if (!entriesArray.length) {
    localStorage.removeItem(storageKey);
  } else {
    localStorage.setItem(storageKey, JSON.stringify(entriesArray));
  }
}

function loadEntriesMap(storageKey: string, ttlMs: number, nowMs: number): Record<string, Entry> {
  try {
    const entriesArr: StoredEntry[] = JSON.parse(localStorage.getItem(storageKey) ?? '[]');
    return entriesArr.reduce((acc, entry) => {
      if (entry.a + ttlMs < nowMs) {
        return acc;
      }

      acc[entry.k] = { addedAt: entry.a, value: entry.v };
      return acc;
    }, {} as Record<string, Entry>);
  } catch (e) {
    return {};
  }
}
