import { NgModule } from '@angular/core';
import { COOKIE_POLICY_INITIALIZER, CookiePolicyService } from './cookie-policy.service';
import { CookiePolicyPopupComponent } from './cookie-policy-popup/cookie-policy-popup.component';
import { ButtonComponent } from '../shared/components/button/button.component';



@NgModule({
  declarations: [
    CookiePolicyPopupComponent,
  ],
  imports: [
    ButtonComponent
  ],
  providers: [
    CookiePolicyService,
    COOKIE_POLICY_INITIALIZER,
  ]
})
export class CookiePolicyModule {
}


