import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren, EventEmitter,
  Input, OnDestroy, Output,
  QueryList
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TabComponent } from './tab/tab.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterViewInit {
  @Input() set active(active: string | null) {
    this.active$.next(active);
  }
  @Output() activeChange = new EventEmitter();
  @ContentChildren(TabComponent) tabs?: QueryList<TabComponent>;
  listeners: Subscription[] = [];
  active$ = new BehaviorSubject<string | null>(null);

  constructor() {
    this.active$.pipe(
      untilDestroyed(this),
    ).subscribe((value) => {
      this.updateSelectedTab(value);
    });
  }

  onTabClick(value: string | null) {
    if (this.active$.value === value) {
      return;
    }

    this.active$.next(value);
    this.activeChange.emit(value);
  }

  ngAfterViewInit(): void {
    this.updateSelectedTab(this.active$.value);
    this.addTabsOnClickListeners();
    this.tabs?.changes?.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.updateSelectedTab(this.active$.value);
    });
  }

  ngOnDestroy(): void {
    for (const listener of this.listeners) {
      listener.unsubscribe();
    }
  }

  private updateSelectedTab(value: string | null) {
    const options = this.tabs?.toArray() ?? [];

    for (const option of options) {
      option.setSelected(option.value === value);
    }
  }

  private addTabsOnClickListeners() {
    const options = this.tabs?.toArray() ?? [];

    for (const option of options) {
      this.listeners.push(
        option.clicked.subscribe(() => {
          this.onTabClick(option.value);
        })
      );
    }
  }
}
