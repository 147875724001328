<div class="container">
  <div class="footer-inner">
    <p>© {{ currentYear }} 1inch</p>
    <div class="footer-links">
      <a href="https://submit.1inch.io/b/portfolio-features/" target="_blank">
        Feature Request
        <svg>
          <use xlink:href="assets/icons/arrow-link.svg#arrow-link"></use>
        </svg>
      </a>
    </div>
  </div>
</div>
