import { Pipe, PipeTransform } from '@angular/core';
import { EnsService } from '../../ens/ens.service';
import { filter, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { PrettyHashPipe } from './pretty-hash.pipe';

@Pipe({
  name: 'ensLookup',
  pure: true,
  standalone: true,
})
export class EnsLookupPipe implements PipeTransform {
  constructor(
    readonly ens: EnsService
  ) {
  }

  transform(addressOrHash: string, defaultValue?: string): Observable<string> {
    return this.ens.lookupENSAddress(addressOrHash).pipe(
      filter(Boolean),
      startWith(defaultValue ?? new PrettyHashPipe().transform(addressOrHash))
    )
  }
}
