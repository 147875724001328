import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyFormat } from './currency-format';
import { formatCurrency, FormatterOptions } from './formatters';

@Pipe({
  name: 'currencyFormat',
  pure: true,
  standalone: true,
})
export class CurrencyFormatPipe implements PipeTransform {

  transform(value: number, format: CurrencyFormat | string, options?: FormatterOptions): string {
    return formatCurrency(value, format, options);
  }
}
