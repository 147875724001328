import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnsService } from './ens.service';



@NgModule({
  declarations: [],
  providers: [
    EnsService,
  ],
  imports: [
    CommonModule
  ]
})
export class EnsModule { }
