import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { QuantorApiService } from "../../../../modules/quantor-api/quantor-api.service";
import { catchError, combineLatest, delay, map, Observable, of, shareReplay, switchMap, tap } from "rxjs";
import { startWith } from "rxjs/operators";
import { WalletConnectService } from "../../../../modules/wallet-connect/wallet-connect.service";
import { ChainValueUsd } from "../../../../modules/quantor-api";
import { EthChainId } from "../../../../modules/shared/eth-chain-id";

interface HeaderState {
  supportedChainIds: EthChainId[],
  currentValueByChains: ChainValueUsd[]
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  state$: Observable<HeaderState>;

  constructor(
    readonly quantorApi: QuantorApiService,
    readonly router: Router,
    readonly walletConnect: WalletConnectService,
    readonly ch: ChangeDetectorRef
  ) {
    const supportedChainIds$ = this.quantorApi.supportedChainIds$;

    const connectedAddresses$ = this.walletConnect.connectedAddresses$.pipe(
      delay(1),
      startWith([]),
      tap(() => this.ch.detectChanges()),
      shareReplay(1),
    );

    const currentValueByChains$ = connectedAddresses$.pipe(
      switchMap((addresses) => {
        return !addresses || !addresses.length
          ? of([])
          : this.quantorApi.getTotalCurrentValue(addresses).pipe(
            map((res) => res.chains),
            catchError(() => of([]))
          )
      }),
    );

    this.state$ = combineLatest([
      supportedChainIds$,
      currentValueByChains$,
    ]).pipe(
      map(([supportedChainIds, currentValueByChains]) => {
        return {
          supportedChainIds,
          currentValueByChains,
        }
      }),
    );
  }

  goToRootPage(e: MouseEvent) {
    e.preventDefault();
    this.router.navigate(['/']);
  }
}
