import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appOutsideClick]'
})
export class OutsideClickDirective {
  @Output() outsideClick = new EventEmitter<Element>();

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(target: Element): void {
    const clickedInside = this.el.nativeElement.contains(target);
    if (!clickedInside) {
      this.outsideClick.emit(target);
    }
  }
}
