import { CurrencyFormat } from './currency-format';

export interface FormatterOptions {
  sign?: 'exceptZero';
  digitsInfo?: string;
}

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol'
});

const cryptoFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

function parseDigitsInfo(digitsInfo: string): any {
  //{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits};
  try {
    const [minIntegerDigits, right] = digitsInfo.split('.');
    const [minFractionDigits, maxFractionDigits] = right.split('-');
    return {
      minimumIntegerDigits: Number(minIntegerDigits),
      minimumFractionDigits: Number(minFractionDigits),
      maximumFractionDigits: Number(maxFractionDigits),
    }
  } catch (e) {
    return {};
  }
}

function applySignOption(formatter: Intl.NumberFormat, sign?: FormatterOptions['sign']): Intl.NumberFormat {
  if (!sign) {
    return formatter;
  }

  const opts = formatter.resolvedOptions();
  return new Intl.NumberFormat(opts.locale, { ...opts, signDisplay: 'exceptZero' });
}

function applyDigitsInfoOption(formatter: Intl.NumberFormat, digitsInfo?: string): Intl.NumberFormat {
  if (!digitsInfo) {
    return formatter;
  }

  const opts = formatter.resolvedOptions();
  return new Intl.NumberFormat(opts.locale, { ...opts, ...parseDigitsInfo(digitsInfo) });
}

function assembleDefaultDigitsInfo(
  value: number,
  formatter: Intl.NumberFormat,
  type: 'crypto' | 'usd' | 'percent',
): string {
  const integersCount = `${Math.abs(Math.round(value))}`.startsWith('0') ? 0 : `${Math.abs(Math.round(value))}`.length;
  const opts = formatter.resolvedOptions();

  if (type === 'crypto') {
    const min = opts.minimumFractionDigits;
    const max = opts.maximumFractionDigits;
    const optimal = integersCount <= 0 ? max : 4 - integersCount;
    const newMax = Math.max(Math.min(optimal, max), 0);
    return `${opts.minimumIntegerDigits}.${Math.min(min, newMax)}-${newMax}`;
  } else if (type === 'usd') {
    const fractionDigits = integersCount > 2 ? 0 : 2;
    return `${opts.minimumIntegerDigits}.${fractionDigits}-${fractionDigits}`;
  } else if (type === 'percent') {
    const fractionDigits = integersCount > 2 ? 0 : 2;
    return `${opts.minimumIntegerDigits}.${fractionDigits}-${fractionDigits}`;
  }

  throw new Error(`Unknown type "${type}"`);
}

export function formatCurrency(value: number, format: CurrencyFormat | string, options?: FormatterOptions): string {
  const isCrypto = format !== 'USD';
  let formatter = isCrypto ? cryptoFormatter : usdFormatter;

  formatter = applySignOption(formatter, options?.sign);
  formatter = applyDigitsInfoOption(
    formatter,
    options?.digitsInfo ?? assembleDefaultDigitsInfo(value, formatter, isCrypto ? 'crypto' : 'usd')
  );

  let formattedValue = formatter.format(value);

  if (isCrypto) {
    formattedValue = formattedValue.replaceAll(',', ' ') + ` ${format}`;
  } else {
    formattedValue = formattedValue.replaceAll(',', ' ');
  }

  return formattedValue;
}

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function removeRedundantZeroes(value: string, sign: '%' | '$'): string {
  const pattern = `.00${sign}`;

  if (value[value.length - 1] !== sign) {
    return value;
  }

  if (value.indexOf(pattern) === value.length - pattern.length) {
    return value.replace(pattern, sign);
  }

  return value;
}

export function formatPercent(value: number, options?: FormatterOptions): string {
  let formatter = percentFormatter;

  formatter = applySignOption(formatter, options?.sign);
  formatter = applyDigitsInfoOption(
    formatter,
    options?.digitsInfo ?? assembleDefaultDigitsInfo(value * 100, formatter, 'percent')
  );

  let formattedValue = formatter.format(value);
  formattedValue = formattedValue.replaceAll(',', ' ');
  formattedValue = removeRedundantZeroes(formattedValue, '%');

  return formattedValue;
}
