import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef, OnDestroy,
  QueryList
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SwitchToggleOptionComponent } from './switch-toggle-option/switch-toggle-option.component';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-switch-toggle',
  templateUrl: './switch-toggle.component.html',
  styleUrls: ['./switch-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SwitchToggleComponent),
    }
  ]
})
export class SwitchToggleComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @ContentChildren(SwitchToggleOptionComponent) options?: QueryList<SwitchToggleOptionComponent>;
  control = new FormControl<string | null>(null);
  subscription?: Subscription;
  listeners: Subscription[] = [];

  constructor() {
    this.subscription = this.control.valueChanges.subscribe((value) => {
      this.updateSelectedOption(value);
    });
  }

  onOptionClick(value: string | null) {
    if (this.control.value === value) {
      return;
    }

    this.control.setValue(value);
  }

  ngAfterViewInit(): void {
    this.updateSelectedOption(this.control.value);
    this.addOptionsOnClickListeners();
  }

  registerOnChange(fn: any): void {
    this.control.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    //
  }

  writeValue(obj: string | null): void {
    this.control.setValue(obj);
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled && !this.control.disabled) {
      this.control.disable();
    } else if (!isDisabled && this.control.disabled) {
      this.control.enable();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    for (const listener of this.listeners) {
      listener.unsubscribe();
    }
  }

  private updateSelectedOption(value: string | null) {
    const options = this.options?.toArray() ?? [];

    for (const option of options) {
      option.setSelected(option.value === value);
    }
  }

  private addOptionsOnClickListeners() {
    const options = this.options?.toArray() ?? [];

    for (const option of options) {
      this.listeners.push(
        option.clicked.subscribe(() => {
          this.onOptionClick(option.value);
        })
      );
    }
  }
}
