<div *ngIf="state$ | async as state" class="header">
  <div class="container">
    <div class="header-content">
      <div class="header-left-col">
        <span class="header-logo" (click)="goToRootPage($event)">
          <div class="header-logo-large-wrapper">
            <img *appForTheme="'dark'" class="header-logo-large header-logo-1inch"
                 src="/assets/logo/logo-explore.svg" alt="1inch Logo"/>
            <img *appForTheme="'light'" class="header-logo-large  header-logo-1inch"
                 src="/assets/logo/logo-explore-alt.svg" alt="1inch Logo"/>
            <img class="header-logo-large header-logo-explore"
                 src="/assets/logo/logo-explore-part.svg" alt="1inch Logo portfolio part"/>
          </div>
          <img class="header-logo-small" src="/assets/logo/logo-small.svg" alt="1inch Logo"/>
        </span>
      </div>
      <div class="header-buttons">
        <app-account-button></app-account-button>
        <app-preferences-button></app-preferences-button>
      </div>
    </div>
  </div>
</div>
