import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';



@Component({
  selector: 'button[appTab]',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @HostBinding('class.selected') selected = false;
  @Input() value: string | null = null;
  @Output() clicked = new EventEmitter<void>();

  constructor(
    private ch: ChangeDetectorRef
  ) {
  }

  setSelected(selected: boolean): void {
    this.selected = selected;
    this.ch.detectChanges();
  }

  @HostListener('click')
  onClick(): void {
    this.clicked.emit();
  }
}
