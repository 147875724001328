import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';

const ANALYTIC_PREFIX = 'explore/';

@Injectable({
  providedIn: 'root',
})
export class WebAnalyticsService {
  get dataLayer() {
    return this.gtm.getDataLayer();
  }

  constructor(
    readonly gtm: GoogleTagManagerService,
    readonly router: Router,
  ) {
  }

  event(event: string, data?: object): void {
    const dataLayer = this.dataLayer;

    if (!dataLayer) {
      return;
    }

    dataLayer.push({
      event: `${ANALYTIC_PREFIX}${event}`,
      ...(data ?? {}),
    });

    if (!environment.production) {
      console.log('analytics', `${ANALYTIC_PREFIX}${event}`, data);
    }
  }
}

export const WEB_ANALYTICS_INITIALIZER: Provider = {
  provide: APP_INITIALIZER,
  deps: [WebAnalyticsService],
  multi: true,
  useFactory: (analytics: WebAnalyticsService) => () => {
    return of(true);
  }
};
