export const getBrowserVersion = (): BrowserVersion => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isOpera = window.opera || window.opr || (userAgent.indexOf(' opr/') > -1) ||
    (userAgent.indexOf(' coast/') > -1) || (userAgent.indexOf(' opios/') > -1);
  // Check if the Opera browser is Opera Mini or Opera Mobile in regular mode (called High Savings Mode)
  const isOperaHigh = (userAgent.indexOf('opr/') > -1) && (userAgent.indexOf('mobile') > -1) && (userAgent.indexOf('presto/') < 0);
  // Check if the Opera browser is Opera Mini in Extreme Savings Mode
  const isOperaExtreme = (userAgent.indexOf('opera mini/') > -1) && (userAgent.indexOf('presto/') > -1);

  const version = (
    userAgent.match(/.+(?:rv|it|ra|ie)[/: ]([\d.]+)/) || [0, '0']
  )[1];

  return {
    version: version ? version.toString() : null,
    ie: (/msie/.test(userAgent) || /trident\/7/.test(userAgent)) && !/opera/.test(userAgent),
    firefox: (
      /mozilla/.test(userAgent) && !/(compatible|webkit)/.test(userAgent)
    ),
    opera: isOpera,
    operaMini: (isOperaHigh || isOperaExtreme),
    webkit: /webkit/.test(userAgent),
    safari: /safari|applewebkit/.test(userAgent) && !/chrome/.test(userAgent),
    edge: /edge/.test(userAgent),
    chrome: /chrome/.test(userAgent)
  };
};

export const getBrowserVersionClasses = (): string[] => {
  const browser = getBrowserVersion();
  const classes = [];

  if (browser.ie) {
    const version = parseFloat(browser.version ?? '0');

    classes.push('is-browser-ie', 'is-browser-ie' + version);
    if (version === 7 || version === 8) {
      classes.push('is-browser-ie7-8');
    }
  } else if (browser.edge) {
    classes.push('is-browser-edge');
  } else if (browser.firefox) {
    classes.push('is-browser-firefox');
  } else if (browser.safari) {
    classes.push('is-browser-safari');
  } else if (browser.chrome) {
    classes.push('is-browser-chrome');
  } else if (browser.operaMini) {
    classes.push('is-browser-opera-mini');
  } else if (browser.opera) {
    classes.push('is-browser-opera');
  }

  return classes;
};

export interface BrowserVersion {
  version: string|null;
  ie: boolean;
  firefox: boolean;
  opera: boolean;
  operaMini: boolean;
  webkit: boolean;
  safari: boolean;
  edge: boolean;
  chrome: boolean;
}
