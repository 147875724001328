import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnInit
} from '@angular/core';
import { TOAST_DATA, TOAST_ID, TOAST_OFFSET, TOAST_STATE, ToastData, ToastState } from '../entities';
import { BehaviorSubject, merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastMessageComponent implements OnInit {
  @HostBinding('class') get classes(): string {
    return `state-${this.state.value} type-${this.data.type}`;
  }
  @HostBinding('style.--offset') get offsetProp(): number {
    return this.offset.value;
  }

  constructor(
    readonly ch: ChangeDetectorRef,
    @Inject(TOAST_ID) readonly id: number,
    @Inject(TOAST_DATA) readonly data: ToastData,
    @Inject(TOAST_STATE) readonly state: BehaviorSubject<ToastState>,
    @Inject(TOAST_OFFSET) readonly offset: BehaviorSubject<number>,
  ) {
  }

  ngOnInit(): void {
    merge(this.state, this.offset).pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.ch.markForCheck();
    });
  }
}
