import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, map } from 'rxjs';

const BACKGROUND_TYPES = ['purple', 'blue'] as const;
type BackgroundConfig = typeof BACKGROUND_TYPES[number];
const BACKGROUND_CONFIG_KEY = 'background';

interface BackgroundState {
  type: BackgroundConfig;
}

@Component({
  selector: 'app-background',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackgroundComponent {
  readonly state$ = new BehaviorSubject<BackgroundState>({ type: 'purple' });

  constructor(
    readonly router: Router
  ) {
    const routeChanges$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => router.routerState.snapshot.root)
    );

    const backgroundConfig$ = routeChanges$.pipe(
      map((route) => this.getBackgroundConfig(route) ?? 'purple')
    )

    backgroundConfig$.subscribe(config => {
      this.state$.next({ type: config });
    });
  }

  private getBackgroundConfig(route: ActivatedRouteSnapshot, parentConfig: BackgroundConfig | null = null): BackgroundConfig | null {
    let currentConfig: BackgroundConfig | null = route.routeConfig?.data?.[BACKGROUND_CONFIG_KEY] ?? null;

    if (!currentConfig || !BACKGROUND_TYPES.includes(currentConfig)) {
      currentConfig = parentConfig;
    }

    if (route.children?.length) {
      return this.getBackgroundConfig(route.children[0], currentConfig);
    }

    return currentConfig;
  }
}
