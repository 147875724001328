import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, shareReplay } from 'rxjs';
import { headers, params } from './entities/helpers';
import { EthChainId } from "../shared/eth-chain-id";
import { OpportunitiesParam, ProtocolsParam } from "./entities/options";
import {
  AssetProtocol,
  CurrentValue,
  CurrentValueDetails,
  OpportunityResponse,
  OverviewResponse,
  Protocol,
  ProtocolsKey,
  SupportedToken,
  Token
} from "./entities/assets";

export const QUANTOR_API_URL = new InjectionToken<string>('__QUANTOR_API_URL__');
export const MARKET_STATES_API_URL = new InjectionToken<string>('__MARKET_STATES_API_URL__');

const DEFAULT_SUPPORTED_CHAINS = [EthChainId.ethereum, EthChainId.binance, EthChainId.polygon]

@Injectable({
  providedIn: 'root'
})
export class QuantorApiService {
  public readonly supportedChainIds$ = this.getSupportedChainIds().pipe(
    shareReplay(1)
  );

  public readonly supportedTokens$ = this.getSupportedTokens().pipe(
    shareReplay(1),
  )

  public readonly supportedProtocols$ = this.getSupportedProtocols().pipe(
    shareReplay(1),
  )

  public readonly popularTokens$ = this.getPopularTokens().pipe(
    shareReplay(1),
  )

  constructor(
    @Inject(QUANTOR_API_URL) readonly apiUrl: string,
    @Inject(MARKET_STATES_API_URL) readonly marketStatesApiUrl: string,
    readonly http: HttpClient,
  ) {
  }

  getPopularTokens(): Observable<SupportedToken[]> {
    return this.http.get<SupportedToken[]>(
      `${this.marketStatesApiUrl}/popular_tokens`,
      {...headers({cache: true, auth: true})}
    )
      .pipe(
        map((tokens) => tokens.map((token) => this.mapSupportedToken(token))),
        catchError(() => of([])),
      )
  }

  getSupportedChainIds(): Observable<EthChainId[]> {
    return this.http.get<EthChainId[]>(
      `${this.marketStatesApiUrl}/supported_chains`,
      {...headers({cache: true, auth: true})}
    )
      .pipe(catchError(() => of(DEFAULT_SUPPORTED_CHAINS)))
  }

  getSupportedTokens(): Observable<SupportedToken[]> {
    return this.http.get<Token[]>(
      `${this.marketStatesApiUrl}/supported_tokens`,
      {...headers({cache: true, auth: true})}
    )
      .pipe(map((tokens) => tokens.map((token) => this.mapSupportedToken(token))));
  }

  getSupportedProtocols(): Observable<Protocol[]> {
    const protocolSymbolSet = new Set<ProtocolsKey>();

    return this.http.get<Protocol[]>(
      `${this.marketStatesApiUrl}/supported_protocols`,
      {...headers({cache: true, auth: true})}
    )
      .pipe(
        map((protocols: Protocol[]) => protocols.reduce((acc, curr) => {
          if (!protocolSymbolSet.has(curr.symbol)) {
            protocolSymbolSet.add(curr.symbol);
            acc.push(curr);
          }

          return acc;
        }, [] as Protocol[]))
      );
  }

  getTotalCurrentValue(addresses: string[]): Observable<CurrentValue> {
    return this.getCurrentValue(addresses).pipe(
      map(res => res.total)
    );
  }

  getCurrentValue(addresses: string[]): Observable<CurrentValueDetails> {
    type CurrentValueNullable = {
      chains: { chain_id: EthChainId | null, value_usd: number | null }[];
      addresses: { address: string, value_usd: number | null }[];
    };
    type Response = { total: CurrentValueNullable, protocols: Record<AssetProtocol, CurrentValueNullable> };
    const replaceNullable = (value: CurrentValueNullable): CurrentValue => ({
      chains: value.chains.map(item => ({chain_id: item.chain_id, value_usd: item.value_usd ?? 0})),
      addresses: value.addresses.map(item => ({address: item.address, value_usd: item.value_usd ?? 0}))
    });

    return this.http.get<Response>(`${this.apiUrl}/portfolio/overview/assets/current_value`, {
      ...headers({cache: true, auth: true}),
      ...params({
        addresses,
      })
    }).pipe(
      map((res) => {
        return {
          total: replaceNullable(res.total),
          protocols: (Object.keys(res.protocols) as AssetProtocol[]).reduce((acc, key) => {
            acc[key] = replaceNullable(res.protocols[key]);
            return acc;
          }, {} as Record<AssetProtocol, CurrentValue>),
        }
      })
    );
  }

  getProtocols(param: ProtocolsParam): Observable<OverviewResponse> {
    return this.http.get<OverviewResponse>(
      `${this.marketStatesApiUrl}/overview`,
      {
        ...headers({cache: true, auth: true}),
        ...params({...param}),
      },
    )
  }

  getOpportunities(param: OpportunitiesParam): Observable<OpportunityResponse> {
    return this.http.get<OpportunityResponse>(
      `${this.marketStatesApiUrl}/opportunity`,
      {
        ...headers({cache: true, auth: true}),
        ...params({...param}),
      },
    )
  }

  private mapSupportedToken(token: Token): SupportedToken {
    return {
      ...token,
      decimals: token.decimals ?? 8,
      name: token.name ?? 'Unknown token',
      symbol: token.symbol ?? 'UNK',
    }
  }
}
