import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'protocols',
    loadChildren: () => import('../modules/protocols/protocols.module').then(m => m.ProtocolsModule),
  },
  {
    path: 'my-opportunities',
    loadChildren: () => import('../modules/my-opportunities/my-opportunities.module').then(m => m.MyOpportunitiesModule),
  },
  {
    path: '**',
    redirectTo: 'protocols'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      useHash: true
    })
  ],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
