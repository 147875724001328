import { HTTP_QUANTOR_AUTH_HEADER } from '../../auth/auth.interceptor';

export function headers({cache, auth}: { cache?: boolean, auth?: boolean }): { headers: Record<string, string> } {
  let headers = {};

  if (cache) {
    headers = {...headers};
  }

  if (auth) {
    headers = {...headers, [HTTP_QUANTOR_AUTH_HEADER]: 'true'};
  }

  return {headers};
}

type ParamType = string | number | boolean;

export type ParamsRecord = Record<string, ParamType[] | ParamType | null>;

export function params(params: ParamsRecord): { params: Record<string, ParamType | ParamType[]> } {
  const parsedParams: Record<string, ParamType | ParamType[]> = {};

  const keys = Object.keys(params);
  for (const key of keys) {
    if (params[key] === null) {
      continue;
    }

    parsedParams[key] = params[key]!;
  }

  return {params: parsedParams};
}
