import { Inject, Injectable, InjectionToken } from '@angular/core';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5'
import { Web3Modal } from '@web3modal/ethers5/dist/types/src/client';
import { ThemeService } from '../theme/theme.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  concat,
  defer,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  race,
  switchMap,
  take,
  timer
} from 'rxjs';
import { Web3ModalEvent } from './entities.lib';
import { ConnectionController, } from '@web3modal/core';
import { loadConnectedWalletIcon } from './connected-wallet-icon-storage';
import { WebAnalyticsService } from "../web-analytics/web-analytics.service";

export const WALLET_CONNECT_PROJECT_ID = new InjectionToken<string>('__WALLET_CONNECT_PROJECT_ID__');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WalletConnectService {
  private modal: Web3Modal;
  private modalEvents$: Observable<Web3ModalEvent['event']>;
  private connectedAddressesSubject = new BehaviorSubject<string[] | null>(null);
  connectedAddresses$ = this.connectedAddressesSubject.asObservable().pipe(
    filter(Boolean),
    distinctUntilChanged()
  );
  connectedWalletIcon$ = loadConnectedWalletIcon(this.connectedAddresses$);

  constructor(
    @Inject(WALLET_CONNECT_PROJECT_ID) readonly projectId: string,
    readonly themeService: ThemeService,
    readonly analytics: WebAnalyticsService,
  ) {
    this.modal = createModal(this.projectId);

    this.modalEvents$ = new Observable<Web3ModalEvent>((subject) => {
      this.modal.subscribeEvents((event) => subject.next({event: event.data.event, type: event.data.type} as any))
    }).pipe(
      map((event) => event.event),
    );

    this.themeService.themeName$.pipe(
      untilDestroyed(this)
    ).subscribe((name) => {
      this.modal.setThemeMode(name === 'light' ? 'light' : 'dark');
    });

    this.modalEvents$
      .pipe(
        filter((event) => event === 'SELECT_WALLET'),
        untilDestroyed(this),
      )
      .subscribe(() => this.analytics.event('wallet_connect_choose_wallet'));

    this.modal.subscribeProvider(({address, provider}) => {
      let addresses: string[] = address ? [address] : [];
      try {
        const metamaskAddresses = [...((provider?.provider as any)?._state?.accounts ?? [])];
        addresses = metamaskAddresses.length > addresses.length ? metamaskAddresses : addresses;
      } catch (ignored) {
        console.error(ignored)
      }

      this.connectedAddressesSubject.next(addresses.map(address => address.toLowerCase()));
    });

    race(
      this.connectedAddresses$.pipe(take(1)),
      timer(150).pipe(
        map(() => {
          if (this.connectedAddressesSubject.value === null) {
            this.connectedAddressesSubject.next([]);
          }
        })
      )
    ).pipe(
      untilDestroyed(this)
    ).subscribe();
  }

  connect(): Observable<string[]> {
    const events: Web3ModalEvent['event'][] = ['MODAL_CLOSE', 'CONNECT_SUCCESS', 'CONNECT_ERROR', 'DISCONNECT_SUCCESS', 'DISCONNECT_ERROR'];
    const successEvents: Web3ModalEvent['event'][] = ['CONNECT_SUCCESS'];

    return concat(
      defer(() => this.disconnect()).pipe(
        catchError(() => EMPTY),
        switchMap(() => EMPTY)
      ),
      defer(() => this.modal.open({view: 'Connect'})).pipe(
        combineLatestWith(this.modalEvents$),
        filter(([_, event]) => events.includes(event)),
        take(1),
        switchMap(([_, event]) => {
          const throwError = () => {
            throw new Error('Failed to connect')
          };

          if (!successEvents.includes(event)) {
            throwError();
          }

          return race(
            timer(3000).pipe(map(throwError)),
            this.connectedAddresses$.pipe(
              filter((addresses) => !!addresses.length),
              take(1)
            )
          );
        })
      )
    );
  }

  disconnect(): Observable<void> {
    return defer(() => {
      return ConnectionController.disconnect();
    });
  }

  get connectedAddresses(): string[] {
    return this.connectedAddressesSubject.value ?? [];
  }
}

function createModal(projectId: string): Web3Modal {
  const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://web3-node.1inch.io'
  };

  const sitepath = `${window.location.protocol}//${window.location.host}`;
  const metadata = {
    name: document.title,
    description: document.querySelector('meta[name="description"]')?.attributes.getNamedItem('content')?.value ?? '',
    url: sitepath,
    icons: [
      `${sitepath}/assets/logo-small.svg`
    ]
  };

  const modal = createWeb3Modal({
    ethersConfig: defaultConfig({metadata}),
    chains: [mainnet],
    defaultChain: mainnet,
    projectId
  });

  return modal;
}
