import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalletConnectService } from './wallet-connect.service';
import { Observable, of } from 'rxjs';



@NgModule({
  declarations: [],
  providers: [
    WalletConnectService,
    {
      provide: APP_INITIALIZER,
      deps: [WalletConnectService],
      multi: true,
      useFactory: (wc: WalletConnectService) => (): Observable<boolean> => {
        return of(true);
      }
    }
  ],
  imports: [
    CommonModule
  ]
})
export class WalletConnectModule { }
