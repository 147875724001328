export enum EthChainId {
  ethereum = 1,
  binance = 56,
  polygon = 137,
  optimism = 10,
  arbitrum = 42161,
  gnosis = 100,
  avalanche = 43114,
  fantom = 250,
  aurora = 1313161554,
  klaytn = 8217,
  zkSyncEra = 324,
  base = 8453,
}
