<div class="header">
  <img class="header-icon" src="assets/cookie.png" alt="Cookie"/>
  <h3 class="tpg-body-bold">Cookies Policy</h3>
</div>

<div class="content">
  <p>We use cookies to ensure that we give you the best experience on our website.</p>
  <p>For more info read <a href="https://1inch.io/assets/1inch_network_privacy_policy.pdf">Privacy Policy</a></p>
</div>

<div class="buttons-wrap">
  <button [fullWidth]="true" appButton (click)="onAnswer(true)" size="s">I agree</button>
  <button [fullWidth]="true" appButton (click)="onAnswer(false)" size="s" type="secondary">Decline</button>
</div>
