<tui-hosted-dropdown
  *tuiLet="(isPreferencesDropdownOpen$ | async) ?? false as isPreferencesOpen"
  tuiDropdownAlign="right"
  [content]="preferencesDropdown"
  [tuiDropdownOffset]="8"
  [open]="isPreferencesOpen"
  (openChange)="isPreferencesDropdownOpen$.next($event)">
  <button appButton kind="icon" class="preferences-button" [class.open]="isPreferencesOpen">
    <svg><use xlink:href="assets/icons/settings4.svg#settings4"></use></svg>
  </button>
</tui-hosted-dropdown>

<ng-template #preferencesDropdown>
  <div class="preferences-dropdown">
    <h3 class="tpg-body-bold">Theme</h3>
    <app-switch-toggle [formControl]="isDarkThemeControl">
      <app-switch-toggle-option [value]="'dark'">
        <svg><use xlink:href="assets/icons/moon.svg#moon"></use></svg>
        Dark
      </app-switch-toggle-option>
      <app-switch-toggle-option [value]="'light'">
        <svg><use xlink:href="assets/icons/sun.svg#sun"></use></svg>
        Light
      </app-switch-toggle-option>
    </app-switch-toggle>
  </div>
</ng-template>
