import { AfterViewInit, Directive, ElementRef, Input, } from '@angular/core';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '*[tuiDropdownClass]',
})
export class TuiDropdownClassDirective implements AfterViewInit {
  @Input() tuiDropdownClass?: string

  constructor(
    readonly elementRef: ElementRef<HTMLElement>
  ) {

  }

  ngAfterViewInit(): void {
    if (this.tuiDropdownClass) {
      const tuiDropdown = this.getTuiDropdown(this.elementRef?.nativeElement ?? null);

      if (tuiDropdown) {
        tuiDropdown.setAttribute(this.tuiDropdownClass, '');
      }
    }
  }

  private getTuiDropdown(root: HTMLElement | null): HTMLElement | null {
    let element: HTMLElement | null = root;

    while (element && element.tagName.toLowerCase() !== 'tui-dropdown') {
      element = element.parentElement;
    }

    return element;
  }
}
