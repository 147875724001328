import { NgModule } from '@angular/core';
import { FloatingPopupService } from './floating-popup.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { FloatingPopupComponent } from './floating-popup/floating-popup.component';
import { FloatingPopupPortalComponent } from './floating-popup-portal/floating-popup-portal.component';
import { ButtonComponent } from '../shared/components/button/button.component';



@NgModule({
  declarations: [
    FloatingPopupComponent,
    FloatingPopupPortalComponent,
  ],
  providers: [
    FloatingPopupService
  ],
  exports: [
    FloatingPopupComponent,
    FloatingPopupPortalComponent
  ],
  imports: [
    AsyncPipe,
    NgIf,
    ButtonComponent
  ]
})
export class FloatingPopupModule { }
