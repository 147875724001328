import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountAddressesService } from './account-addresses.service';
import { Observable, of } from 'rxjs';



@NgModule({
  declarations: [],
  providers: [
    AccountAddressesService,
    {
      provide: APP_INITIALIZER,
      deps: [AccountAddressesService],
      multi: true,
      useFactory: (accountAddressesService: AccountAddressesService) => (): Observable<boolean> => {
        accountAddressesService.trackSelectionChanges().subscribe();
        return of(true);
      }
    }
  ],
  imports: [
    CommonModule
  ]
})
export class AccountAddressesModule { }
