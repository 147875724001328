export const environment = {
  production: false,
  portfolioUrl: 'https://portfolio.1inch.io',
  quantorApiEndpoint: 'https://portfolio-api-staging.1inch.io/v3',
  marketStatesEndpoint: 'https://portfolio-api-staging.1inch.io/market_states/v1',
  walletConnectProjectId: 'dee2bb6d00de20ea586f76c0c38e7338',
  ensApiEndpoint: 'https://domains-staging.1inch.io/v2.0',
  cdn: 'https://cdn.1inch.io',
  googleTagManagerId: 'GTM-NMZZW4Z5',
  googleTagManagerResourcePath: 'https://track.1inch.io/mtg',
};
