import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs";

const TABS: { title: string, link: string, disabled?: boolean }[] = [
  {
    title: 'Protocols',
    link: '/protocols'
  },
  {
    title: 'My opportunities',
    link: '/my-opportunities',
  }
]

@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTabsComponent {
  public readonly tabs = TABS;

  public readonly activeTab$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.router.url),
    map((currentUrl: string) => this.tabs.find(({link}) => currentUrl.includes(link))?.link ?? '')
  );

  constructor(private readonly router: Router) {
  }

  onActiveChange(url: string): void {
    this.router.navigate([url]);
  }
}
