import { Injectable, Type } from '@angular/core';
import { FloatingPopupPortal } from './entities';
import { Observable, ReplaySubject, take } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class FloatingPopupService {
  portalSubject = new ReplaySubject<FloatingPopupPortal>(1);

  registerPortal(portal: FloatingPopupPortal) {
    this.portalSubject.next(portal);
  }

  show(component: Type<any>) {
    this.portalOnce$.subscribe((portal) => {
      portal.show({ component });
    })
  }

  get portalOnce$(): Observable<FloatingPopupPortal> {
    return this.portalSubject.pipe(
      take(1)
    );
  }
}
