import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { ThemeService } from './theme.service';
import { ThemeName } from './entities';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';



@Directive({
  selector: '[appForTheme]',
})
export class ForThemeDirective implements OnDestroy {
  appForTheme$ = new BehaviorSubject<ThemeName | null>(null);
  @Input() set appForTheme(theme: ThemeName | null) {
    this.appForTheme$.next(theme);
  }
  subscription?: Subscription;

  constructor(
    readonly theme: ThemeService,
    readonly templateRef: TemplateRef<unknown>,
    readonly viewContainer: ViewContainerRef
  ) {
    const isVisible$ = combineLatest([
      this.theme.themeName$,
      this.appForTheme$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([theme, targetTheme]) => theme === targetTheme),
      distinctUntilChanged()
    );

    this.subscription = isVisible$.subscribe((isVisible) => {
      this.viewContainer.clear();

      if (isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
