import { NgModule } from "@angular/core";
import { NavigationTabsComponent } from "./components/navigation-tabs.component";
import { CommonModule } from "@angular/common";
import { TabsModule } from "../../modules/shared/components/tabs/tabs.module";
import { RouterModule } from "@angular/router";
import { TuiHintModule } from "@taiga-ui/core";

@NgModule({
  declarations: [NavigationTabsComponent],
  imports: [CommonModule, TabsModule, RouterModule, TuiHintModule],
  exports: [NavigationTabsComponent],
})
export class NavigationTabsModule {
}
