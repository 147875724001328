import { APP_INITIALIZER, Provider } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { catchError, defer, map, Observable, of } from 'rxjs';

export const GOOGLE_TAG_MANAGER_ID = 'googleTagManagerId';
export const GOOGLE_TAG_MANAGER_AUTH = 'googleTagManagerAuth';
export const GOOGLE_TAG_MANAGER_PREVIEW = 'googleTagManagerPreview';
export const GOOGLE_TAG_MANAGER_RESOURCE_PATH = 'googleTagManagerResourcePath';

export const GTM_INITIALIZER: Provider = {
  provide: APP_INITIALIZER,
  deps: [GoogleTagManagerService],
  multi: true,
  useFactory: (gtmService: GoogleTagManagerService) => (): Observable<boolean> => {
    return defer(() => gtmService.addGtmToDom()).pipe(
      map(() => true),
      catchError(() => {
        console.error('GA is blocked with AdBlock.');
        return of(true);
      })
    );
  }
}
