import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterLink } from '@angular/router';
import { TuiHintModule, TuiHostedDropdownModule, TuiLoaderModule } from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { ReactiveFormsModule } from '@angular/forms';
import { PreferencesButtonComponent } from './components/preferences-button/preferences-button.component';
import { SwitchToggleModule } from '../../modules/shared/components/switch-toggle/switch-toggle.module';
import { ButtonComponent } from '../../modules/shared/components/button/button.component';
import { ThemeModule } from '../../modules/theme/theme.module';
import { AccountButtonComponent } from '../../modules/shared/components/account-button/account-button.component';


@NgModule({
  declarations: [
    HeaderComponent,
    PreferencesButtonComponent,
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    TuiHostedDropdownModule,
    RouterLink,
    TuiLetModule,
    ReactiveFormsModule,
    TuiLoaderModule,
    TuiHintModule,
    SwitchToggleModule,
    ButtonComponent,
    ThemeModule,
    AccountButtonComponent,
  ]
})
export class HeaderModule {
}
