import { ChangeDetectionStrategy, Component, HostBinding, Input, } from '@angular/core';
import { CommonModule } from '@angular/common';

export type ButtonSize = 'xl' | 'l' | 's';

export type ButtonKind = 'filled' | 'icon' | 'rounded';

export type ButtonType =
  'transparent' |
  'primary' | 'secondary' |
  'critical-primary' | 'critical-secondary' |
  'warning-primary' | 'warning-secondary' | 'white' | 'main';

@Component({
  imports: [CommonModule],
  standalone: true,
  selector: 'a[appButton], button[appButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @HostBinding('class.full-width') @Input() fullWidth = false;

  @HostBinding('class') get classes() {
    return `size-${this.size} type-${this.type} kind-${this.kind}`;
  }

  @Input() size: ButtonSize = 'l';
  @Input() kind: ButtonKind = 'filled';
  @Input() type: ButtonType = 'primary';
}
