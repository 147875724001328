import { APP_INITIALIZER, Inject, Injectable, Provider } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BrowserVersion, getBrowserVersion, getBrowserVersionClasses } from './utils';
import { Observable, of } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class BrowserVersionService {
  version: BrowserVersion;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.version = getBrowserVersion();
  }

  init(): void {
    const versionClasses = getBrowserVersionClasses();
    this.document.documentElement.classList.add(versionClasses.join(' '));
  }
}



export const BROWSER_VERSION_INITIALIZER: Provider = {
  provide: APP_INITIALIZER,
  deps: [BrowserVersionService],
  multi: true,
  useFactory: (browserVersion: BrowserVersionService) => (): Observable<boolean> => {
    browserVersion.init();
    return of(true);
  }
}
