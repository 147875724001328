import { TUI_ANIMATIONS_DURATION, TuiAlertModule, TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { HttpClientModule } from '@angular/common/http';
import { BackgroundComponent } from '../modules/shared/components/background/background.component';
import { environment } from '../environments/environment';
import { AccountAddressesModule } from '../modules/account-addresses/account-addresses.module';
import { EnsModule } from '../modules/ens/ens.module';
import { ENS_API_URL } from '../modules/ens/ens.service';
import { ThemeModule } from '../modules/theme/theme.module';
import { WalletConnectModule } from '../modules/wallet-connect/wallet-connect.module';
import { WALLET_CONNECT_PROJECT_ID } from '../modules/wallet-connect/wallet-connect.service';
import { ToastModule } from '../modules/toast/toast.module';
import { BrowserVersionModule } from '../modules/browser-version/browser-version.module';
import { FloatingPopupModule } from '../modules/floating-popup/floating-popup.module';
import { CookiePolicyModule } from '../modules/cookie-policy/cookie-policy.module';
import { TuiLetModule } from '@taiga-ui/cdk';
import { NavigationTabsModule } from "./navigation-tabs/navigation-tabs.module";
import { FooterModule } from "../modules/shared/components/footer/footer.module";
import { MARKET_STATES_API_URL, QUANTOR_API_URL } from "../modules/quantor-api/quantor-api.service";
import { AuthModule } from "../modules/auth/auth.module";
import { WebAnalyticsModule } from "../modules/web-analytics/web-analytics.module";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserVersionModule,
    AuthModule,
    ThemeModule,
    TuiRootModule,
    TuiAlertModule,
    EnsModule,
    AccountAddressesModule,
    HeaderModule,
    ToastModule,
    BackgroundComponent,
    WalletConnectModule,
    FloatingPopupModule,
    CookiePolicyModule,
    TuiDialogModule,
    TuiLetModule,
    NavigationTabsModule,
    FooterModule,
    WebAnalyticsModule,
  ],
  providers: [
    {provide: WALLET_CONNECT_PROJECT_ID, useValue: environment.walletConnectProjectId},
    {provide: QUANTOR_API_URL, useValue: environment.quantorApiEndpoint},
    {provide: MARKET_STATES_API_URL, useValue: environment.marketStatesEndpoint},
    {provide: ENS_API_URL, useValue: environment.ensApiEndpoint},
    {provide: TUI_ANIMATIONS_DURATION, useValue: 200},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
