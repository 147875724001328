import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ComponentRef,
  Injector,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ToastMessageComponent } from '../toast-message/toast-message.component';
import {
  TOAST_DATA,
  TOAST_ID,
  TOAST_OFFSET,
  TOAST_STATE,
  ToastOptions,
  ToastPortal,
  ToastState
} from '../entities';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../toast.service';

interface ToastRef {
  ref: ComponentRef<ToastMessageComponent>;
  duration: number | 'indefinite';
  pinned?: boolean;
  appearStartedAt: number;
  idleStartedAt?: number;
  disappearStartedAt?: number;
}

@Component({
  selector: 'app-toast-portal',
  templateUrl: './toast-portal.component.html',
  styleUrls: ['./toast-portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastPortalComponent implements OnDestroy, ToastPortal {
  @ViewChild('viewContainerRef', { read: ViewContainerRef }) viewContainer?: ViewContainerRef;
  nextToastId = 0;
  toasts: Record<number, ToastRef> = {};
  nextToastsLifecycleUpdateTimeoutId: any = null;

  constructor(
    readonly ch: ChangeDetectorRef,
    readonly toast: ToastService,
  ) {
    this.toast.registerPortal(this);
  }

  show(options: ToastOptions): number {
    return this.createToast(options);
  }

  discard(toastId: number) {
    if (!this.toasts[toastId]) {
      return;
    }

    this.makeToastDisappear(this.toasts[toastId]);
  }

  private createToast({ data, pinned, duration }: ToastOptions): number {
    if (!this.viewContainer) {
      throw new Error('ViewContainer is undefined');
    }

    const toastId = this.nextToastId++;
    const injector = Injector.create({
      parent: this.viewContainer.injector,
      providers: [
        { provide: TOAST_DATA, useValue: data },
        { provide: TOAST_OFFSET, useValue: new BehaviorSubject<number>(0) },
        { provide: TOAST_STATE, useValue: new BehaviorSubject<ToastState>('appear') },
        { provide: TOAST_ID, useValue: toastId },
      ]
    });

    const ref = this.viewContainer.createComponent(ToastMessageComponent, { injector });
    this.ch.detectChanges();

    this.discardAllUnpinnedToasts();

    const now = Date.now();
    this.toasts[toastId] = { ref, pinned, duration, appearStartedAt: now };
    this.triggerToastsLifecycleUpdate(now);

    return toastId;
  }

  private triggerToastsLifecycleUpdate(now = Date.now()) {
    clearTimeout(this.nextToastsLifecycleUpdateTimeoutId);

    const toasts = Object.values(this.toasts);
    let minNextUpdate = null;

    for (const toast of toasts) {
      const nextUpdate = this.updateToastLifecycle(toast, now);

      if (nextUpdate !== null && (minNextUpdate === null || minNextUpdate > nextUpdate)) {
        minNextUpdate = nextUpdate;
      }
    }

    this.updateUnpinnedToastOffsets();

    if (minNextUpdate !== null) {
      const tolerance = 5;
      this.nextToastsLifecycleUpdateTimeoutId = setTimeout(
        this.triggerToastsLifecycleUpdate.bind(this),
        minNextUpdate + tolerance
      );
    }
  }

  private updateToastLifecycle(ref: ToastRef, now: number): number | null {
    const state = ref.ref.instance.state.value;
    const appearDuration = 300;
    const disappearDuration = 300;
    const duration = ref.duration === 'indefinite' ? Infinity : ref.duration;
    const idleDuration = Math.max(duration - appearDuration - disappearDuration, 300);

    const itemAppearDuration = now - ref.appearStartedAt;
    const itemIdleDuration = now - (ref.idleStartedAt ?? now);
    const itemDisappearDuration = now - (ref.disappearStartedAt ?? now);

    if (state === 'appear' && itemAppearDuration >= appearDuration) {
      ref.ref.instance.state.next('idle');
      ref.idleStartedAt = now;
      return idleDuration;
    } else if (state === 'appear') {
      return appearDuration - itemAppearDuration;
    } else if (state === 'idle' && itemIdleDuration >= idleDuration) {
      ref.ref.instance.state.next('disappear');
      ref.disappearStartedAt = now;
      return disappearDuration;
    } else if (state === 'idle') {
      return idleDuration - itemIdleDuration;
    } else if (state === 'disappear' && itemDisappearDuration >= disappearDuration) {
      delete this.toasts[ref.ref.instance.id];
      ref.ref.destroy();
      return null;
    } else if (state === 'disappear') {
      return disappearDuration - itemDisappearDuration;
    }

    throw new Error('Unexpected toast state');
  }

  private discardAllUnpinnedToasts() {
    const toasts = Object.values(this.toasts)
      .filter(toast => !toast.pinned);

    for (const toast of toasts) {
      this.makeToastDisappear(toast);
    }
  }

  private updateUnpinnedToastOffsets() {
    const toasts = Object.values(this.toasts);
    const pinnedToasts = toasts.filter(toast => toast.pinned);
    const unpinnedToasts = toasts.filter(toast => !toast.pinned);

    const offset = pinnedToasts.filter(toast => toast.ref.instance.state.value !== 'disappear').length;
    for (const toast of unpinnedToasts) {
      if (toast.ref.instance.offset.value !== offset) {
        toast.ref.instance.offset.next(offset);
      }
    }
  }

  private makeToastDisappear(toast: ToastRef) {
    const state = toast.ref.instance.state.value;
    const now = Date.now();

    if (state !== 'disappear') {
      toast.ref.instance.state.next('disappear');
      toast.disappearStartedAt = now;
    }

    this.triggerToastsLifecycleUpdate(now);
  }

  ngOnDestroy(): void {
    clearTimeout(this.nextToastsLifecycleUpdateTimeoutId);
  }
}
