import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FLOATING_POPUP_CLOSER } from '../../floating-popup/entities';
import { Subject } from 'rxjs';
import { CookiePolicyService } from '../cookie-policy.service';

@Component({
  selector: 'app-cookie-policy-popup',
  templateUrl: './cookie-policy-popup.component.html',
  styleUrls: ['./cookie-policy-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyPopupComponent {
  constructor(
    readonly cookie: CookiePolicyService,
    @Inject(FLOATING_POPUP_CLOSER) readonly closer: Subject<void>
  ) {
  }

  onAnswer(isPositive: boolean) {
    this.cookie.saveAnswer(isPositive);
    this.closer.next();
  }
}
