import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { AuthService } from './auth.service';

export const HTTP_QUANTOR_AUTH_HEADER = 'quantor-auth-http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has(HTTP_QUANTOR_AUTH_HEADER)) {
      return next.handle(request);
    }

    request.headers.delete(HTTP_QUANTOR_AUTH_HEADER);

    const authToken = this.auth.getAccessToken();
    let modifiedRequest = request;

    if (authToken) {
      modifiedRequest = modifiedRequest.clone({
        headers: modifiedRequest.headers.set('Authorization', `Bearer ${authToken}`),
      });
    }

    return next.handle(modifiedRequest).pipe(
      catchError((err) => {
        const isForbidden = err?.error?.statusCode === 403;

        if (isForbidden) {
          this.handleUnauthorizedError(err?.error?.message);
        }

        throw err;
      }),
    );
  }

  private handleUnauthorizedError(message: string): void {
    /*this.dialog.closeAll();
    this.toast.showError(message);*/
    this.auth.logout().subscribe();
  }
}
