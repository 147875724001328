import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ComponentRef,
  Injector,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { FloatingPopupService } from '../floating-popup.service';
import {
  FLOATING_POPUP_CLOSER,
  FLOATING_POPUP_OPTIONS,
  FLOATING_POPUP_STATE,
  FloatingPopupOptions,
  FloatingPopupPortal,
  FloatingPopupState
} from '../entities';
import { FloatingPopupComponent } from '../floating-popup/floating-popup.component';

interface FloatingPopupRef {
  ref: ComponentRef<FloatingPopupComponent>;
  appearStartedAt: number;
  disappearStartedAt?: number;
  destroy(): void;
}

@Component({
  selector: 'app-floating-popup-portal',
  templateUrl: './floating-popup-portal.component.html',
  styleUrls: ['./floating-popup-portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingPopupPortalComponent implements AfterViewInit, OnDestroy, FloatingPopupPortal {
  @ViewChild('viewContainerRef', { read: ViewContainerRef }) viewContainer?: ViewContainerRef;
  popup: FloatingPopupRef | null = null;
  queue: FloatingPopupOptions[] = [];
  nextPopupsLifecycleUpdateTimeoutId: any = null;

  constructor(
    readonly ch: ChangeDetectorRef,
    readonly service: FloatingPopupService,
  ) {
    this.service.registerPortal(this);
  }

  show(options: FloatingPopupOptions): void {
    if (!this.viewContainer || this.popup) {
      this.queue.push(options);
    } else {
      this.createPopup(options);
    }
  }

  private createPopup(options: FloatingPopupOptions): void {
    if (!this.viewContainer) {
      throw new Error('ViewContainer is undefined');
    }

    const closer = new Subject<void>();
    const state = new BehaviorSubject<FloatingPopupState>('appear');
    const injector = Injector.create({
      parent: this.viewContainer.injector,
      providers: [
        { provide: FLOATING_POPUP_STATE, useValue: state },
        { provide: FLOATING_POPUP_CLOSER, useValue: closer },
        { provide: FLOATING_POPUP_OPTIONS, useValue: options },
      ]
    });

    const ref = this.viewContainer.createComponent(FloatingPopupComponent, { injector });
    this.ch.detectChanges();

    const subscription = closer.pipe(
      take(1),
    ).subscribe(() => {
      if (this.popup) {
        this.makePopupDisappear(this.popup);
      }
    });

    this.popup = {
      ref,
      appearStartedAt: Date.now(),
      destroy: () => {
        ref.destroy();
        subscription.unsubscribe();
      }
    };

    this.triggerLifecycleUpdate();
  }

  triggerLifecycleUpdate(now = Date.now()) {
    clearTimeout(this.nextPopupsLifecycleUpdateTimeoutId);

    let updateIn = null;

    if (this.popup) {
      updateIn = this.updatePopupLifecycle(this.popup, now, () => {
        this.popup = null;
        this.triggerLifecycleUpdate(now);
      });
    } else if (this.queue.length) {
      const options = this.queue[0];
      this.queue = this.queue.slice(1, this.queue.length);
      this.createPopup(options);
    }

    if (updateIn !== null) {
      const tolerance = 5;
      this.nextPopupsLifecycleUpdateTimeoutId = setTimeout(
        this.triggerLifecycleUpdate.bind(this),
        updateIn + tolerance
      );
    }
  }

  private updatePopupLifecycle(ref: FloatingPopupRef, now: number, onDestroy: () => void): number | null {
    const state = ref.ref.instance.state.value;
    const appearDuration = 300;
    const disappearDuration = 300;

    const itemAppearDuration = now - ref.appearStartedAt;
    const itemDisappearDuration = now - (ref.disappearStartedAt ?? now);

    if (state === 'appear' && itemAppearDuration >= appearDuration) {
      ref.ref.instance.state.next('idle');
      return null;
    } else if (state === 'appear') {
      return appearDuration - itemAppearDuration;
    } else if (state === 'idle') {
      return null;
    } else if (state === 'disappear' && itemDisappearDuration >= disappearDuration) {
      ref.destroy();
      onDestroy();
      return null;
    } else if (state === 'disappear') {
      return disappearDuration - itemDisappearDuration;
    }

    throw new Error('Unexpected state');
  }

  private makePopupDisappear(popup: FloatingPopupRef) {
    const state = popup.ref.instance.state.value;
    const now = Date.now();

    if (state !== 'disappear') {
      popup.ref.instance.state.next('disappear');
      popup.disappearStartedAt = now;
    }

    this.triggerLifecycleUpdate(now);
  }

  ngAfterViewInit(): void {
    this.triggerLifecycleUpdate();
  }

  ngOnDestroy(): void {
    clearTimeout(this.nextPopupsLifecycleUpdateTimeoutId);
  }
}
