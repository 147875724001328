import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { isValidAddress } from '../shared/entities';

export class SelectedAddresses {
  private selected$ = new BehaviorSubject<string[]>(loadSelectedAddresses(this.localStorageKey));
  valueChanges = this.selected$.asObservable();

  constructor(
    readonly localStorageKey: string,
    readonly limit: number | null = null
  ) {
  }

  saveSelectionChanges(): Observable<void> {
    return this.selected$.asObservable().pipe(
      tap((selected) => saveSelectedAddresses(this.localStorageKey, selected)),
      map(() => undefined)
    )
  }

  toggleSelected(address: string, isSelected: boolean | undefined = undefined): boolean {
    if (isSelected === undefined) {
      isSelected = !this.isSelected(address);
    }

    return this.toggleSelectedSet([address], isSelected);
  }

  toggleSelectedSet(addresses: string[], isSelected: boolean): boolean {
    if (isSelected) {
      const alreadySelected = new Set(this.selectedAddresses);
      let newAddresses = addresses
        .map(a => a.toLowerCase())
        .filter(addr => !alreadySelected.has(addr));

      let sliced = false;
      if (this.limit !== null && this.limit < (alreadySelected.size + newAddresses.length)) {
        newAddresses = newAddresses.slice(0, Math.max(this.limit - alreadySelected.size, 0));
        sliced = true;
      }

      this.selected$.next(Array.from(new Set([...this.selectedAddresses, ...newAddresses])));
      return !sliced;
    } else {
      this.selected$.next(this.selectedAddresses.filter(a => !addresses.map(a => a.toLowerCase()).includes(a)));
      return true;
    }
  }

  isSelected(address: string): boolean {
    return this.selectedAddresses.includes(address.toLowerCase());
  }

  get selectedAddresses(): string[] {
    return this.selected$.value.map(a => a.toLowerCase());
  }
}

function loadSelectedAddresses(key: string): string[] {
  try {
    const encoded = localStorage.getItem(key) as string;
    return encoded
      .split('-')
      .filter(isValidAddress)
      .sort()
  } catch (e) {
    return [];
  }
}

function saveSelectedAddresses(key: string, addresses: string[]): void {
  try {
    const encoded = addresses
      .map(a => a.toLowerCase())
      .filter(isValidAddress)
      .sort()
      .join('-');

    if (encoded === '') {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, encoded);
    }
  } catch (e) {
    console.error(e)
  }
}
