import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-elastic-container',
  templateUrl: './elastic-container.component.html',
  styleUrls: ['./elastic-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElasticContainerComponent {
  @HostBinding('class.with-offsets') @Input() withOffsets = false;
}
