import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ToastOptions {
  data: ToastData;
  duration: number | 'indefinite';
  pinned?: boolean;
}

export interface ToastData {
  message: string;
  icon?: string;
  type: 'info' | 'error' | 'warning';
}

export type ToastState = 'appear' | 'disappear' | 'idle';

export const TOAST_STATE = new InjectionToken<BehaviorSubject<ToastState>>('__TOAST_STATE__');
export const TOAST_OFFSET = new InjectionToken<BehaviorSubject<number>>('__TOAST_OFFSET__');
export const TOAST_DATA = new InjectionToken<ToastData>('__TOAST_DATA__');
export const TOAST_ID = new InjectionToken<number>('__TOAST_ID__');

export interface ToastPortal {
  discard(toastId: number): void;
  show(options: ToastOptions): number;
}
