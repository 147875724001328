import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject, Injector,
  OnInit, ViewChild, ViewContainerRef
} from '@angular/core';
import { FloatingPopupState, FLOATING_POPUP_STATE, FLOATING_POPUP_CLOSER, FLOATING_POPUP_OPTIONS, FloatingPopupOptions } from '../entities';
import { BehaviorSubject, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-floating-popup',
  templateUrl: './floating-popup.component.html',
  styleUrls: ['./floating-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingPopupComponent implements OnInit, AfterViewInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef }) viewContainer?: ViewContainerRef;
  @HostBinding('class') get classes(): string {
    return `state-${this.state.value}`;
  }

  constructor(
    readonly ch: ChangeDetectorRef,
    @Inject(FLOATING_POPUP_STATE) readonly state: BehaviorSubject<FloatingPopupState>,
    @Inject(FLOATING_POPUP_OPTIONS) readonly options: FloatingPopupOptions,
    @Inject(FLOATING_POPUP_CLOSER) readonly closer: Subject<void>,
  ) {

  }

  ngOnInit(): void {
    this.state.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.ch.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    if (!this.viewContainer) {
      throw new Error('ViewContainer is undefined');
    }

    const injector = Injector.create({
      parent: this.viewContainer.injector,
      providers: [
        { provide: FLOATING_POPUP_CLOSER, useValue: this.closer },
      ]
    });

    this.viewContainer.createComponent(this.options.component, { injector });
    this.ch.detectChanges();
  }
}
