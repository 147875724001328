<ng-container *ngIf="state$ | async as state">
  <tui-hosted-dropdown
    *ngIf="!isMobile"
    tuiDropdownAlign="right"
    [content]="state.allAddresses.length ? settingsDropdown : null"
    [tuiDropdownOffset]="dropdownOffset ?? 8"
    [tuiDropdownMaxHeight]="999"
    [open]="state.isSettingsDropdownOpen"
    (openChange)="isSettingsDropdownOpen$.next($event); $event ? toggleEditMode(false) : null">
    <ng-container [ngTemplateOutlet]="buttonContent">
    </ng-container>
  </tui-hosted-dropdown>

  <div *ngIf="isMobile" appOutsideClick (outsideClick)="close()">
    <div (click)="toggleContent()">
      <ng-container [ngTemplateOutlet]="buttonContent">
      </ng-container>
    </div>

    <div *ngIf="state.isSettingsDropdownOpen" class="setting-dropdown-mobile" #dropdownMobileRef>
      <ng-container [ngTemplateOutlet]="settingsDropdown">
      </ng-container>
    </div>
  </div>

  <ng-template #buttonContent>
    <button *ngIf="!state.allAddresses.length" appButton type="primary" size="l" class="connect-wallet-button"
            (click)="connect()">
      <svg>
        <use xlink:href="assets/icons/wallet.svg#wallet"></use>
      </svg>
      Connect <span>Wallet</span>
    </button>

    <button
      *ngIf="state.allAddresses.length"
      class="account-button tpg-body-bold"
      [class.open]="state.isSettingsDropdownOpen">
      <span *ngIf="state.selectedAddressesIcons.length" class="account-button-icon">
        <app-account-image *ngFor="let address of state.selectedAddressesIcons" [address]="address"/>
      </span>
      <span class="account-button-label">
        {{ state.selectedAddresses.length === 0
        ? 'Choose account'
        : state.selectedAddresses.length === 1
          ? (state.selectedAddresses[0] | ensLookup | async)
          : (state.selectedAddresses.length + ' My Accounts') }}
      </span>
      <svg>
        <use xlink:href="assets/icons/arrow.svg#arrow"></use>
      </svg>
    </button>
  </ng-template>

  <ng-template #settingsDropdown>
    <div tuiDropdownClass="settings-dropdown" class="settings-dropdown" [class.edit-mode]="state.isEditMode"
         [class.no-addresses]="!state.allAddresses.length">
      <div class="section">
        <div *ngIf="state.allAddresses.length" class="section-header">
          <h3 class="tpg-body-bold">{{ state.allAddresses.length === 1 ? 'My Account' : 'My Accounts' }}</h3>
          <span class="edit-link tpg-button-regular" (click)="toggleEditMode()">
            {{ !state.isEditMode ? 'Manage' : 'Done' }}
          </span>
        </div>
        <div *ngIf="state.allAddresses.length" class="account-options-list-wrap">
          <tui-scrollbar class="account-options-scrollbar">
            <div class="account-options-list">
              <button
                *ngFor="let address of state.connectedAddresses"
                (click)="!state.isEditMode ? toggleSelected(address) : null"
                class="account-option" [class.selected]="state.selectedAddresses.includes(address)">
                <ng-container [ngTemplateOutlet]="accountOption"
                              [ngTemplateOutletContext]="{ address, icon: 'disconnect_account', walletIcon: state.connectedWalletIcon }"></ng-container>
              </button>
              <button
                *ngFor="let address of state.watchlistAddresses"
                (click)="!state.isEditMode ? toggleSelected(address) : null"
                class="account-option" [class.selected]="state.selectedAddresses.includes(address)">
                <ng-container [ngTemplateOutlet]="accountOption"
                              [ngTemplateOutletContext]="{ address, icon: 'cross' }"></ng-container>
              </button>
            </div>
          </tui-scrollbar>
        </div>
      </div>
      <div class="section-floating">
        <app-elastic-container>
          <ng-container *ngIf="state.isEditMode">
            <button
              appButton type="secondary" [fullWidth]="true" (click)="connect(!!state.connectedAddresses.length)"
            >
              {{ state.connectedAddresses.length ? 'Change' : 'Connect' }} Wallet
            </button>
          </ng-container>

          <a *ngIf="!state.isEditMode && state.connectedAddresses.length"
             appButton [fullWidth]="true" class="show-portfolio" (click)="showPortfolio(state.connectedAddresses)">
            Show Portfolio
          </a>
        </app-elastic-container>
      </div>
    </div>

    <ng-template #accountOption let-address="address" let-icon="icon" let-walletIcon="walletIcon">
      <div class="account-option-image">
        <app-account-image [address]="address"></app-account-image>
        <div *ngIf="walletIcon" class="wallet-icon">
          <img [src]="walletIcon" alt="Connected Wallet Icon"/>
        </div>
      </div>
      <span class="account-option-content">
        <span class="account-option-name tpg-special-m">
          {{ address | ensLookup | async }}
          <a
            (click)="$event.stopPropagation()"
            [routerLink]="'/address/' + address"><svg><use
            xlink:href="assets/icons/arrow-right-3.svg#arrow-right-3"></use></svg></a>
        </span>
        <small *tuiLet="state.valuesByAddress[address] as value" class="account-option-value tpg-caption-regular">
          <span *ngIf="value === null || value === undefined" class="pending"></span>
          {{ (value !== null && value !== undefined) ? (value | currencyFormat:'USD') : '' }}
        </small>
      </span>
      <span class="account-option-actions">
        <button *ngIf="state.isEditMode" (click)="removeAddress(address)" appButton kind="icon">
          <svg><use [attr.xlink:href]="'assets/icons/' + icon + '.svg#' + icon"></use></svg>
        </button>
        <span *ngIf="!state.isEditMode" class="account-option-mark"></span>
      </span>
    </ng-template>
  </ng-template>
</ng-container>
