export const THEME_NAMES = ['dark', 'light'] as const;
export type ThemeName = typeof THEME_NAMES[number];

type HexColor = string;

export interface Theme {
  name: ThemeName;
  palette: ThemePalette;
}

export interface ThemePalette {
  staticBrand: HexColor;
  staticWhite: HexColor;
  staticWhite60: HexColor;
  staticWhite25: HexColor;
  staticWhite10: HexColor;
  staticBlack: HexColor;
  staticGreen: HexColor;

  textIconsMain: HexColor;
  textIconsGray500: HexColor;
  textIconsGray400: HexColor;
  textIconsGray300: HexColor;
  textIconsGray200: HexColor;
  textIconsApprove: HexColor;
  textIconsWarning: HexColor;
  textIconsError: HexColor;

  bgMain: HexColor;
  bg2ry: HexColor;
  bg3ry: HexColor;
  bgInfo2ry: HexColor;
  bgSuccess2ry: HexColor;
  bgWarning2ry: HexColor;
  bgError2ry: HexColor;
  bgWarning: HexColor;
  bgError: HexColor;
  bgInputMain: HexColor;
  bgInput2ry: HexColor;
  bgTabSelected: HexColor;
  bgBody: HexColor;
  bgInput3ry: HexColor;

  borderMain: HexColor;
  border2ry: HexColor;
  borderFocused: HexColor;

  dropdownShadow: HexColor;

  statesButtonDisabled: HexColor;
  statesControlDisabled: HexColor;
  statesRipple: HexColor;
  statesBrandHover: HexColor;
  statesInfo2ryHover: HexColor;
  statesApproveHover: HexColor;
  statesSuccess2ryHover: HexColor;
  statesWarningHover: HexColor;
  statesWarning2ryHover: HexColor;
  statesErrorHover: HexColor;
  statesError2ryHover: HexColor;
}

export function applyThemePaletteVariables(palette: ThemePalette, element: HTMLElement) {
  const cssVariables = themePaletteToCssVariables(palette);

  for (const key in cssVariables) {
    element.style.setProperty(key, cssVariables[key]);
  }
}

function themePaletteToCssVariables(theme: ThemePalette): Record<string, string> {
  return Object.keys(theme).reduce((acc, key) => {
    acc[castToCssVariableName(key)] = (theme as any)[key];
    return acc;
  }, {} as Record<string, string>);
}


const DIGIT_REGEX = /\d/;

function castToCssVariableName(name: string): string {
  let cssName = '';
  let prevDigit = false;

  for (const symbol of name) {
    const isDigit = DIGIT_REGEX.test(symbol);
    const isFirstDigit = isDigit && !prevDigit;

    if (symbol.toLowerCase() !== symbol || isFirstDigit) {
      cssName += `-${symbol.toLowerCase()}`;
    } else {
      cssName += symbol;
    }

    if (isDigit) {
      prevDigit = true;
    }
  }

  return `--${cssName}`;
}
