import { NgModule } from '@angular/core';
import { WEB_ANALYTICS_INITIALIZER, WebAnalyticsService } from './web-analytics.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { GOOGLE_TAG_MANAGER_ID, GOOGLE_TAG_MANAGER_RESOURCE_PATH, GTM_INITIALIZER } from './entities';
import { environment } from '../../environments/environment';


@NgModule({
  declarations: [],
  providers: [
    WebAnalyticsService,
    GTM_INITIALIZER,
    WEB_ANALYTICS_INITIALIZER,
    {provide: GOOGLE_TAG_MANAGER_ID, useValue: environment.googleTagManagerId},
    {provide: GOOGLE_TAG_MANAGER_RESOURCE_PATH, useValue: environment.googleTagManagerResourcePath},
  ],
  imports: [
    GoogleTagManagerModule,
  ]
})
export class WebAnalyticsModule {
}
