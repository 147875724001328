import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  filter,
  map,
  Observable,
  pairwise,
  ReplaySubject,
  shareReplay,
  tap,
} from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { createHomeUrl } from './home-url';
import { PrettyHashPipe } from '../../pipes/pretty-hash.pipe';
import { AccountAddressesService } from '../../../account-addresses/account-addresses.service';
import { EnsService } from '../../../ens/ens.service';
import { startWith } from 'rxjs/operators';
import { TuiHostedDropdownModule, TuiScrollbarModule } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';
import { EnsLookupPipe } from '../../pipes/ens-lookup.pipe';
import { AccountImageComponent } from '../account-image/account-image.component';
import { ButtonComponent } from '../button/button.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { addressesToUriComponent, uriComponentToAddresses } from '../../entities';
import { WalletConnectService } from '../../../wallet-connect/wallet-connect.service';
import { ToastService } from '../../../toast/toast.service';
import { ElasticContainerModule } from '../elastic-container/elastic-container.module';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TuiDropdownClassDirective } from '../dropdown/tui-dropdown-class.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OutsideClickDirective } from '../../utils/outside-click.derective';
import { getValuesByAddresses$ } from "./values-by-addresses";
import { QuantorApiService } from "../../../quantor-api/quantor-api.service";
import { CurrencyFormatPipe } from "../../pipes/currency-format.pipe";
import { environment } from '../../../../environments/environment';
import { WebAnalyticsService } from "../../../web-analytics/web-analytics.service";


interface AccountButtonState {
  homeUrl: string | null;
  isHomeUrlDisplayed: boolean;
  connectedAddresses: string[];
  watchlistAddresses: string[];
  selectedAddresses: string[];
  allAddresses: string[];
  selectedAddressesIcons: string[];
  isSettingsDropdownOpen: boolean;
  isEditMode: boolean;
  connectedWalletIcon: SafeResourceUrl | null;
  valuesByAddress: Record<string, number>;
}

@UntilDestroy()
@Component({
  selector: 'app-account-button',
  templateUrl: './account-button.component.html',
  styleUrls: ['./account-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    ButtonComponent,
    EnsLookupPipe,
    PrettyHashPipe,
    AccountImageComponent,
    CommonModule,
    TuiHostedDropdownModule,
    TuiLetModule,
    ElasticContainerModule,
    TuiDropdownClassDirective,
    OutsideClickDirective,
    TuiScrollbarModule,
    CurrencyFormatPipe,
  ]
})
export class AccountButtonComponent implements OnInit, OnDestroy {
  @ViewChild('inputRef') addAddressInputRef?: ElementRef;
  @ViewChild('dropdownMobileRef') dropdownMobileRef?: ElementRef;
  @Input() dropdownOffset?: number;

  @Input() set currentAddresses(addresses: string[] | null) {
    this.currentAddresses$.next(addresses);
  }

  state$: Observable<AccountButtonState>;
  isSettingsDropdownOpen$ = new BehaviorSubject(false);
  isEditMode$ = new BehaviorSubject(false);
  currentAddresses$ = new ReplaySubject<string[] | null>();
  isMobile = window.innerWidth <= 480
  startMobileHeight = window.visualViewport?.height

  constructor(
    readonly quantorApi: QuantorApiService,
    readonly accountAddresses: AccountAddressesService,
    readonly walletConnect: WalletConnectService,
    readonly ch: ChangeDetectorRef,
    readonly router: Router,
    readonly toast: ToastService,
    readonly ens: EnsService,
    readonly sanitizer: DomSanitizer,
    readonly webAnalyticsService: WebAnalyticsService,
  ) {
    const connected$ = this.walletConnect.connectedAddresses$.pipe(
      delay(1),
      startWith([]),
      tap(() => this.ch.detectChanges()),
      shareReplay(1),
    );
    const valuesByAddress$ = getValuesByAddresses$(
      combineLatest([
        connected$,
        this.accountAddresses.watchlistAddresses$,
      ]).pipe(
        map(([c, w]) => ([...new Set([...c, ...w])]))
      ),
      this.quantorApi,
    ).pipe(
      startWith({} as Record<string, number>)
    );

    this.state$ = combineLatest([
      connected$,
      this.walletConnect.connectedWalletIcon$.pipe(startWith(null)),
      this.accountAddresses.selectedAddresses$,
      this.accountAddresses.watchlistAddresses$,
      this.isSettingsDropdownOpen$,
      this.isEditMode$,
      valuesByAddress$,
    ]).pipe(
      map(([connectedAddresses, connectedWalletIcon, selected, watchlist, isSettingsDropdownOpen, isEditMode, valuesByAddress]) => {
        const {url: homeUrl, displayed: isHomeUrlDisplayed} = createHomeUrl();
        const watchlistAddresses = watchlist.filter(w => !connectedAddresses.map(a => a.toLowerCase()).includes(w.toLowerCase()));

        return {
          homeUrl,
          connectedWalletIcon: connectedWalletIcon ? this.sanitizer.bypassSecurityTrustResourceUrl(connectedWalletIcon) : null,
          isHomeUrlDisplayed,
          isSettingsDropdownOpen,
          isEditMode: isEditMode || (!connectedAddresses.length && !watchlist.length),
          selectedAddresses: selected,
          selectedAddressesIcons: uriComponentToAddresses(addressesToUriComponent(selected)).slice(0, 3),
          connectedAddresses,
          watchlistAddresses,
          allAddresses: [...connectedAddresses, ...watchlistAddresses],
          valuesByAddress,
        }
      })
    );
  }

  private navigateAfterFirstConnect() {
    this.accountAddresses.selectedAddresses$.pipe(
      pairwise(),
      filter(([prev, current]) => !prev.length && !!current.length),
      delay(1),
      map(() => this.accountAddresses.selectedAddresses),
      untilDestroyed(this),
    ).subscribe((addresses) => {
      this.router.navigate(['/address', addressesToUriComponent(addresses)]);
    })
  }

  ngOnInit() {
    window.visualViewport?.addEventListener('resize', this.resizeHandler);
  }

  ngOnDestroy() {
    window.visualViewport?.removeEventListener('resize', this.resizeHandler);
  }

  @HostListener('window:focus')
  onWindowFocus(): void {
    this.ch.detectChanges();
  }

  @HostListener('window:resize')
  onResize(): void {
    if (window.innerWidth <= 480) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }

  private resizeHandler(): void {
    const settingDropdownMobile = document.querySelector('.setting-dropdown-mobile') as HTMLDivElement
    let height = 0

    if (/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
      height = window.visualViewport?.height || 0
    }

    if (settingDropdownMobile && height) {
      settingDropdownMobile.style.bottom = `${Number(this.startMobileHeight) - height}px`;
    }
  }

  connect(isChange = false) {
    this.webAnalyticsService.event(isChange ? 'click_on_change_wallet_in_modal' : 'click_connect_wallet_button');

    this.walletConnect.connect().subscribe((addresses) => {
      if (addresses.length) {
        this.webAnalyticsService.event('wallet_connected', {address: addresses[0]});
      }

      this.isEditMode$.next(false);
      this.accountAddresses.toggleSelectedSet(addresses, true);
    });
  }

  logout() {
    this.walletConnect.disconnect().subscribe();
  }

  removeAddress(address: string) {
    if (this.walletConnect.connectedAddresses.map(a => a.toLowerCase()).includes(address?.toLowerCase())) {
      this.logout();
      this.toast.show(`Wallet disconnected!`);
    } else {
      this.accountAddresses.toggleWatchlistAddress(address, false);
      this.toast.show(`${new PrettyHashPipe().transform(address)} – removed from Watchlist!`);
    }
  }

  toggleSelected(address: string) {
    const {selectedAddresses, maxSelectionAddressesCount} = this.accountAddresses;

    if (selectedAddresses.length >= maxSelectionAddressesCount && !selectedAddresses.includes(address)) {
      this.toast.error('Selection limited to 10 addresses');
      return;
    }

    this.accountAddresses.toggleSelected(address);
  }

  toggleContent() {
    const currentValue = this.isSettingsDropdownOpen$.value;
    this.isSettingsDropdownOpen$.next(!currentValue);

    if (currentValue) {
      this.toggleEditMode(false)
    }
  }

  close() {
    this.isSettingsDropdownOpen$.next(false);
  }

  toggleEditMode(enable = !this.isEditMode$.value, focusAddAddress?: boolean) {
    if (enable) {
      this.webAnalyticsService.event('click_on_manage_in_modal');
    }

    if (enable === this.isEditMode$.value) {
      return;
    }

    this.isEditMode$.next(enable);

    if (focusAddAddress) {
      setTimeout(() => {
        this.addAddressInputRef?.nativeElement?.focus();
      }, 0);
    }
  }

  showPortfolio(addresses: string[]) {
    this.webAnalyticsService.event('show_portfolio_clicked');

    window.open(`${environment.portfolioUrl}/#/address/${addresses[0]}`, '_blank');
  }
}
