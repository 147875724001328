import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { filter, Observable, of, take, timer } from 'rxjs';
import { FloatingPopupService } from '../floating-popup/floating-popup.service';
import { CookiePolicyPopupComponent } from './cookie-policy-popup/cookie-policy-popup.component';
import { NavigationEnd, Router } from '@angular/router';

const key = 'cookie-policy-user-answer';



@Injectable({
  providedIn: 'root'
})
export class CookiePolicyService {
  constructor(
    readonly floatingPopup: FloatingPopupService,
    readonly router: Router
  ) {}

  init() {
    timer(3000).pipe(
      take(1)
    ).subscribe(() => {
      if (this.loadAnswer() === null) {
        this.floatingPopup.show(CookiePolicyPopupComponent);
      }
    });
  }

  saveAnswer(isPositive: boolean) {
    window.localStorage.setItem(key, isPositive ? '1' : '0');
  }

  loadAnswer(): boolean | null {
    const result = window.localStorage.getItem(key);

    if (result === '1') {
      return true;
    } else if (result === '0') {
      return false;
    } else {
      return null;
    }
  }
}



export const COOKIE_POLICY_INITIALIZER: Provider = {
  provide: APP_INITIALIZER,
  deps: [CookiePolicyService],
  multi: true,
  useFactory: (cookies: CookiePolicyService) => (): Observable<boolean> => {
    cookies.init();
    return of(true);
  }
}
