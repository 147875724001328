import {EthChainId} from "../eth-chain-id";

export interface Explorer {
  name: string;
  txUrlTemplate: string;
  addressUrlTemplate: string;
}

export const EXPLORERS: Record<EthChainId, Explorer> = {
  [EthChainId.ethereum]: {
    name: 'Etherscan',
    txUrlTemplate: 'https://etherscan.io/tx/{{hash}}',
    addressUrlTemplate: 'https://etherscan.io/address/{{address}}',
  },
  [EthChainId.binance]: {
    name: 'BscScan',
    txUrlTemplate: 'https://bscscan.com/tx/{{hash}}',
    addressUrlTemplate: 'https://bscscan.com/address/{{address}}',
  },
  [EthChainId.polygon]: {
    name: 'PolygonScan',
    txUrlTemplate: 'https://polygonscan.com/tx/{{hash}}',
    addressUrlTemplate: 'https://polygonscan.com/address/{{address}}',
  },
  [EthChainId.optimism]: {
    name: 'Optimistic Ethereum Explorer',
    txUrlTemplate: 'https://optimistic.etherscan.io/tx/{{hash}}',
    addressUrlTemplate: 'https://optimistic.etherscan.io/address/{{address}}',
  },
  [EthChainId.arbitrum]: {
    name: 'Arbitrum Explorer',
    txUrlTemplate: 'https://arbiscan.io/tx/{{hash}}',
    addressUrlTemplate: 'https://arbiscan.io/address/{{address}}',
  },
  [EthChainId.gnosis]: {
    name: 'GnosisScan',
    txUrlTemplate: 'https://gnosisscan.io/tx/{{hash}}',
    addressUrlTemplate: 'https://gnosisscan.io/address/{{address}}',
  },
  [EthChainId.avalanche]: {
    name: 'SnowTrace Explorer',
    txUrlTemplate: 'https://snowtrace.io/tx/{{hash}}',
    addressUrlTemplate: 'https://snowtrace.io/address/{{address}}',
  },
  [EthChainId.fantom]: {
    name: 'FTMScan',
    txUrlTemplate: 'https://ftmscan.com/tx/{{hash}}',
    addressUrlTemplate: 'https://ftmscan.com/address/{{address}}',
  },
  [EthChainId.aurora]: {
    name: 'Aurora Explorer',
    txUrlTemplate: 'https://aurorascan.dev/tx/{{hash}}',
    addressUrlTemplate: 'https://aurorascan.dev/address/{{address}}',
  },
  [EthChainId.klaytn]: {
    name: 'Klaytn Scope',
    txUrlTemplate: 'https://scope.klaytn.com/tx/{{hash}}',
    addressUrlTemplate: 'https://scope.klaytn.com/address/{{address}}',
  },
  [EthChainId.zkSyncEra]: {
    name: 'ZKSync Era Explorer',
    txUrlTemplate: 'https://explorer.zksync.io/tx/{{hash}}',
    addressUrlTemplate: 'https://explorer.zksync.io/address/{{address}}',
  },
  [EthChainId.base]: {
    name: 'BaseScan',
    txUrlTemplate: 'https://basescan.org/tx/{{hash}}',
    addressUrlTemplate: 'https://basescan.org/address/{{address}}',
  },
}
